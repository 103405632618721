import { HttpError } from "@app/http/base";
import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types";
import { IS_PROD } from "./constants";

const Logger = {
  log: console.log,
  warn: console.warn,
  error: (err: HttpError, captureContext?: CaptureContext) => {
    if (!IS_PROD) {
      console.error(err);
    }

    Sentry.captureException(err, captureContext);
  },
  captureException: Sentry.captureException,
  captureEvent: Sentry.captureEvent,
  captureMessage: Sentry.captureMessage,
};

export default Logger;
