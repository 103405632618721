import memCache from "memory-cache";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { papiHttp, apiHttp } from "./base";
import { GET, POST } from "@app/utilities/constants";
import {
  TournamentModel,
  ITournamentStat,
  ITicketStatus,
  IRosterMatchStat,
  IPaymentData,
  IValidateCampaignCode,
  IRosterStatResponse,
  ICompleteStripePayment,
  ISearchTournamentsQuery,
  ICompleteShift4Payment,
  IShift4Session,
} from "@models/TournamentModel";
import { IPagination } from "@models/utils";
import { TeamModel, ITournamentRosterData } from "@models/TeamModel";
import TournamentMappers from "./mappers/TournamentMappers";
import Logger from "@app/utilities/logger";

const cancelTokens: { [key: string]: CancelTokenSource } = {};
const tournamentsSearchCache = new memCache.Cache<string, IPagination<TournamentModel>>();

const hashTournamentQuery = (query: ISearchTournamentsQuery) => {
  const { upcoming, teamSize, paid, page, pageSize, sort, featured } = query || {};
  return [upcoming, teamSize, paid, page, pageSize, sort, featured].map((v) => v || "-").join("|");
};

const tournamentHttp = {
  fetchSingleTournamentById: (tournamentId: number): Promise<TournamentModel> => {
    return papiHttp(GET, `/tournament/${tournamentId}`, null).then((response: AxiosResponse) => {
      const { data } = response,
        tournament = TournamentMappers.mapTournamentModel(data);

      if (!tournament) {
        throw new Error(`Failed to fetch tournament data of ${tournamentId}.`);
      }

      return tournament;
    });
  },
  searchTournaments: (filterQuery: ISearchTournamentsQuery, cancelTokenName = "searchTournaments"): Promise<IPagination<TournamentModel>> => {
    const cacheKey = hashTournamentQuery(filterQuery),
      result = tournamentsSearchCache.get(cacheKey);

    if (result) {
      return Promise.resolve(result);
    } else {
      const cancelToken = cancelTokens[cancelTokenName] || axios.CancelToken.source();
      cancelTokens[cancelTokenName] = cancelToken;

      return papiHttp(GET, `/tournament/search`, filterQuery, {
        cancelToken: cancelTokens[cancelTokenName].token,
      }).then((response: AxiosResponse) => {
        const {
            data: { page, pageSize: rowsPerPage, totalCount, tournaments: data },
          } = response,
          tournamentsArr: TournamentModel[] = [];

        if (data && data.length) {
          for (let i = 0, len = data.length; i < len; i++) {
            const tournament = TournamentMappers.mapTournamentModel(data[i]);
            tournamentsArr.push(tournament);
          }
        }

        const result = {
          totalCount,
          page,
          rowsPerPage,
          data: tournamentsArr,
        };

        // Cache prismic data for the next 15 minutes.
        tournamentsSearchCache.put(cacheKey, result, 6e4 * 15);

        return result;
      });
    }
  },
  fetchUpcomingTournament: (): Promise<TournamentModel> => {
    return papiHttp(GET, `/tournament/upcoming`, null).then((response: AxiosResponse) => {
      const { data } = response,
        tournament = TournamentMappers.mapTournamentModel(data);

      if (!tournament) {
        throw new Error("Failed to fetch upcoming tournament data");
      }

      return tournament;
    });
  },
  fetchTournamentList: (): Promise<TournamentModel[]> => {
    return papiHttp(GET, `/tournament`, null).then((response: AxiosResponse) => {
      const { data } = response,
        result = [];

      if (data && data.length) {
        for (let i = 0, len = data.length; i < len; i++) {
          const tournament = TournamentMappers.mapTournamentModel(data[i]);
          result.push(tournament);
        }
      }

      return result;
    });
  },

  /**
   * Create a ticket for free
   * @param arg Object containing "tournamentId" and "campaignCode"
   * @return An object containing "valid_ticket_count" and "used_ticket_count"
   */
  admitFreeTicket: (arg: { tournamentId: number; campaignCode?: string }): Promise<ITicketStatus> => {
    let url = `/tournament/createTicket/${arg.tournamentId}`;

    if (arg.campaignCode) {
      url += `?campaign_code=${arg.campaignCode}`;
    }

    return apiHttp(POST, url, {}).then((response: AxiosResponse) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data: { valid_ticket_count, used_ticket_count },
      } = response;

      if (valid_ticket_count || used_ticket_count) {
        return {
          validTicket: valid_ticket_count,
          usedTicket: used_ticket_count,
        } as ITicketStatus;
      }

      throw new Error("Failed to admit free ticket");
    });
  },

  /**
   * Retrieves user-tournament ticket status
   * @param tournamentId the ID of the tournament
   * @return An object containing "valid_ticket_count" and "used_ticket_count"
   */
  getTicketStatus: (tournamentId: number): Promise<ITicketStatus> => {
    return apiHttp(GET, `/tournament/ticketStatus/${tournamentId}`, null).then((response: AxiosResponse) => {
      const {
        data: { valid_ticket_count, used_ticket_count },
      } = response;
      if (response.data) {
        return {
          validTicket: valid_ticket_count,
          usedTicket: used_ticket_count,
        } as ITicketStatus;
      }

      throw new Error("Failed to get ticket status");
    });
  },

  /**
   * Retrieves Stripe purchase redirect for purchasing tournament
   * @param arg Object containing "tournamentId" and "campaignCode"
   * @return A string containing the purchase session id
   */
  getStripeRedirect: (arg: { tournamentId: number; campaignCode?: string }): Promise<string> => {
    let url = `/payment/stripe/beginPayment/${arg.tournamentId}`;

    if (arg.campaignCode) {
      url += `?campaign_code=${arg.campaignCode}`;
    }

    return apiHttp(POST, url, null).then((response: AxiosResponse) => {
      if (response.data) {
        const {
          data: { payment_session_id },
        } = response;

        if (payment_session_id) {
          return payment_session_id;
        }
      }

      throw new Error("Failed to get purchase session");
    });
  },

  completeStripePayment: (payment_session_id: string): Promise<ICompleteStripePayment> => {
    return apiHttp(POST, `/payment/stripe/completePayment`, {
      payment_session_id,
    }).then((response: AxiosResponse) => {
      if (response.data) {
        const { payment_session_id: paymentSessionId, payment_status: paymentStatus } = response.data;

        return {
          paymentSessionId,
          paymentStatus,
        } as ICompleteStripePayment;
      }

      throw new Error("Failed to admit paid ticket");
    });
  },

  /**
   * Retrieves Shift4 purchase redirect for purchasing tournament
   * @param arg Object containing "tournamentId" and "campaignCode"
   * @return A string containing the purchase session id
   */
  getShift4Redirect: (arg: { tournamentId: number; campaignCode?: string }): Promise<IShift4Session> => {
    let url = `/payment/shift4/beginPayment/${arg.tournamentId}`;

    if (arg.campaignCode) {
      url += `?campaign_code=${arg.campaignCode}`;
    }

    return apiHttp(POST, url, null).then((response: AxiosResponse) => {
      if (response.data) {
        const {
          data: { token, url, server, paymentId },
        } = response;

        return {
          token,
          url,
          server,
          paymentId,
        };
      }

      throw new Error("Failed to get purchase session");
    });
  },

  completeShift4Payment: (paymentId: string, sessionId: string, postalCode: string, expirationDate: string): Promise<ICompleteShift4Payment> => {
    return apiHttp(
      POST,
      `/payment/shift4/completePayment`,
      {
        paymentId,
        uniqueId: sessionId,
        postalCode: postalCode || "",
        expirationDate: expirationDate || "",
      },
      {
        timeout: 7e4,
      }
    )
      .then((response: AxiosResponse) => {
        if (response.data) {
          const { paymentId, paymentStatus } = response.data;

          return {
            paymentId,
            paymentStatus,
          } as ICompleteShift4Payment;
        }

        throw new Error("Failed to admit paid ticket");
      })
      .catch((err) => {
        Logger.error(err);
        return {
          paymentId,
          paymentStatus: "failed",
        } as ICompleteShift4Payment;
      });
  },

  getRostersAsTournamentStats: async (arg: { tournamentId: string | number; tier: string | number; page: number }): Promise<IPagination<ITournamentStat>> => {
    const page = arg.page || 0;
    const tier = arg.tier || 401;
    return papiHttp(GET, `/stats/tournament/${arg.tournamentId}/roster/tier/${tier}?page=${page}`, null)
      .then((response: AxiosResponse) => {
        if (response.data) {
          const { totalCount, results: tournamentRostersData } = response.data,
            results = tournamentRostersData as ITournamentRosterData[];

          if (totalCount && results && results.length) {
            return {
              totalCount,
              page,
              rowsPerPage: 100,
              data: TournamentMappers.mapTournamentRostersToStats(results),
            };
          }
        }

        throw new Error("Failed to get roster as tournament stats");
      })
      .catch(() => {
        return {
          totalCount: 0,
          page,
          rowsPerPage: 100,
          data: [],
        };
      });
  },

  getTournamentStats: async (arg: { tournamentId: string | number; tier: string | number; page: number }): Promise<IPagination<ITournamentStat>> => {
    const page = arg.page || 0;
    const tier = arg.tier || 401;
    return papiHttp(GET, `/stats/tournament/${arg.tournamentId}/tier/${tier}?page=${page}`, null)
      .then((response: AxiosResponse) => {
        if (response.data) {
          const { total_count, results } = response.data;

          if (total_count && results && results.length) {
            return {
              totalCount: total_count,
              page,
              rowsPerPage: 100,
              data: results as ITournamentStat[],
            };
          }
        }

        throw new Error("Failed to get tournament stats");
      })
      .catch(() => {
        return {
          totalCount: 0,
          page,
          rowsPerPage: 100,
          data: [],
        };
      });
  },

  getRosterStatDetails: async (arg: { tournamentId: string | number; rosterId: string; top: boolean }): Promise<IRosterMatchStat[]> => {
    const getTopMatches = arg.top || false;

    return papiHttp(GET, `/stats/tournament/${arg.tournamentId}/roster/${arg.rosterId}/users?top=${getTopMatches}`, null)
      .then((response: AxiosResponse) => {
        if (response?.data) {
          const rosterStatResponse = response.data as IRosterStatResponse;
          return TournamentMappers.mapRosterMatchStats(rosterStatResponse);
        }

        throw new Error("Failed to get roster stat details");
      })
      .catch(() => {
        return [];
      });
  },

  /**
   * Retrieves user-touranment status of its campaign code availability
   * @deprecated
   * @param tournamentId the ID of the tournament
   */
  getPaymentData: async (tournamentId: string | number): Promise<IPaymentData> => {
    const defaultResponse = {
      creatorCode: "",
      creatorCodeEditable: true,
      creatorCodeExpiryDate: 0,
      creatorCodeValidTo: 0,
    };

    return apiHttp(GET, `/payment/paymentData/${tournamentId}`, null)
      .then((response: AxiosResponse) => {
        if (response && response.data) {
          return {
            creatorCode: response.data.creator_code || "",
            creatorCodeEditable: !!response.data.creator_code_editable,
            creatorCodeExpiryDate: response.data.creator_code_expiry_date || 0,
            creatorCodeValidTo: response.data.creator_code_valid_at || 0,
          };
        }

        throw new Error("Failed to get payment data");
      })
      .catch(() => {
        return defaultResponse;
      });
  },

  validateCampaignCode: async (arg: { tournamentId: string | number; campaignCode: string }): Promise<IValidateCampaignCode | null> => {
    return apiHttp(GET, `/payment/validateCampaign/${arg.tournamentId}/${arg.campaignCode}`, null).then((response: AxiosResponse) => {
      if (response?.data) {
        return {
          discountedPrice: response.data?.discounted_price,
          expiryDate: response.data?.campaign_code_expiry_date || 0,
        };
      }

      throw new Error("Failed to get payment data");
    });
  },

  /**
   * Returns user's roster status regardless user has a roster or not
   * If the user doesn't have any active roster, "response.rosterId" will be 0 - and others properties will be defaults.
   * @see TournamentMappers.mapRoster
   * @returns TeamModel
   */
  getActiveRoster: async (tournamentId: number): Promise<TeamModel> => {
    return apiHttp(GET, `/tournament/activeRoster/${tournamentId}`, null).then((response: AxiosResponse) => {
      /**
       * If user is not part of any team, this API returns 204 with empty response
       * If user was part of team, this API returns TeamModel with status of `unregistered`.
       */
      return TournamentMappers.mapRoster(response.data);
    });
  },
};

export default tournamentHttp;
