export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";

// Apps
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || "https://gamefaced.gg";
export const APP_TITLE = process.env.NEXT_PUBLIC_APP_TITLE || "Call of Duty Warzone Tournaments | GameFace";

// Metas
export const META_URL = process.env.NEXT_PUBLIC_META_URL || APP_URL;
export const META_AUTHOR = "GameFace Warzone Tournaments";
export const META_TITLE = process.env.NEXT_PUBLIC_META_TITLE || APP_TITLE;
export const META_DESCRIPTION =
  process.env.NEXT_PUBLIC_META_DESCRIPTION ||
  "Fully-automated Call of Duty Warzone tournaments with skill-based divisions, live leaderboards, and anti-cheat features. Always play against people of your skill level for large cash prizes and rewards!";
export const META_TWITTER = process.env.NEXT_PUBLIC_META_TWITTER || "@gamefacedgg";

// API Endpoints
export const API_PORT = process.env.NEXT_PUBLIC_APP_API_HOST || "";
export const PAPI_PORT = process.env.NEXT_PUBLIC_APP_PAPI_HOST || "";
export const PRISMIC_PORT = process.env.NEXT_PUBLIC_APP_PRISMIC_URL || "";

// Payments
export const STRIPE_PUBLISH_KEY = process.env.NEXT_PUBLIC_APP_STRIPE_PUBLISHABLE_KEY || "";

// Discord OAuth
export const DISCORD_OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_APP_DISCORD_CLIENT_ID || "";
export const DISCORD_OAUTH_REDIRECT_URI = (process.env.NEXT_PUBLIC_APP_URL || "") + (process.env.NEXT_PUBLIC_APP_DISCORD_REDIRECT_URI || "/auth/login/oauth?mode=discord") || "";

// Twitch OAuth
export const TWITCH_OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_APP_TWITCH_CLIENT_ID || "";
export const TWITCH_OAUTH_REDIRECT_URI = (process.env.NEXT_PUBLIC_APP_URL || "") + (process.env.NEXT_PUBLIC_APP_TWITCH_REDIRECT_URI || "/auth/login/oauth?mode=discord") || "";

// Discord Server Invitation Link
export const DISCORD_INVITATION_LINK = process.env.NEXT_PUBLIC_APP_DISCORD_SERVER_LINK || "";

// GA
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_APP_GA_MEASUREMENT || "UA-180708726-1";

// GTM
export const GTM_ID = process.env.NEXT_PUBLIC_APP_GA_GTM || "GTM-TB2PJLP";

// Facebook Pixel
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_APP_FACEBOOK_PIXEL_ID || "";
export const FB_DOMAIN_VRF = process.env.NEXT_PUBLIC_APP_FACEBOOK_DOMAIN_VERIFICATION || "";

// AWS Cognito
export const COGNITO_REGION = process.env.NEXT_PUBLIC_COGNITO_REGION || "us-east-2";
export const COGNITO_POOL_ID = process.env.NEXT_PUBLIC_COGNITO_POOL_ID || "";
export const COGNITO_POOL_CLIENT_ID = process.env.NEXT_PUBLIC_COGNITO_POOL_CLIENT_ID || "";
export const COGNITO_OAUTH_REDIR_URL = process.env.NEXT_PUBLIC_COGNITO_OAUTH_REDIR_URL || "";
export const COGNITO_OAUTH_REDIR_LOGOUT_URL = process.env.NEXT_PUBLIC_COGNITO_OAUTH_REDIR_LOGOUT_URL || "";
export const COGNITO_OAUTH_DOMAIN = process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN || "";

// Socials
export const SOCIAL_FACEBOOK = process.env.NEXT_PUBLIC_APP_SOCIAL_FACEBOOK || "https://www.facebook.com/GameFacedGG";
export const SOCIAL_IG = process.env.NEXT_PUBLIC_APP_SOCIAL_IG || "https://www.instagram.com/gamefacegg";
export const SOCIAL_TWITTER = process.env.NEXT_PUBLIC_APP_SOCIAL_TWITTER || "https://www.twitter.com/GameFacedGG";

// Feature flags
export const TEAM_CREATION_FEATURE_ENABLED = process.env.NEXT_PUBLIC_APP_TEAMCREATION_FEAT === "true";
export const STRIPE_FEATURE_ENABLED = process.env.NEXT_PUBLIC_APP_STRIPE_FEAT === "true";

// Sentry
export const SENTRY_DSN = process.env.NEXT_PUBLIC_APP_SENTRY_DSN || "";

// Query methods
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
