import moment from "moment";
import { IPrismicContentType } from "./PrismicModel";

export interface IPrismicTournamentDetails {
  details: IPrismicContentType[];
  rule: IPrismicContentType[];
  scoring: IPrismicContentType[];
}

export enum TournamentState {
  OPEN = "registration_open",
  IN_PROGRESS = "in_progress",
  UNDER_REVIEW = "under_review",
  COMPLETED = "completed",
  CANCELLED = "canceled",
}
export interface ITicketStatus {
  validTicket: number;
  usedTicket: number;
}

export interface ICompleteStripePayment {
  paymentSessionId: string;
  paymentStatus: string;
}
export interface ICompleteShift4Payment {
  paymentId: string;
  paymentStatus: string;
}

export interface IShift4Session {
  token: string;
  url: string;
  server: string;
  paymentId: string;
}
export interface IRosterStatResponse {
  stats: {
    total_count: number;
    matches: {
      match_id: string;
      placement: number;
      placement_points: number;
      kills: number;
      score: number;
      created_at: number;
      players: {
        user_id: string;
        timeMovingPct: number;
        kills: number;
        kdRatio: number;
        headshotsPct: number;
        deaths: number;
        damage: number;
      }[];
    }[];
  };
  user_info: {
    gameface_user_id: string;
    username: string;
    avatar_url: string;
  }[];
}

export interface ITournamentStat {
  score: number;
  tier_no: number;
  roster_id: string;
  roster_name: string;
  top_kills: number;
  top_placement_points: number;
  total_match_count: number;
  finalized: boolean;
  disqualified: boolean;
  flagged: boolean;
}

export interface IRosterStatPlayer {
  uid: string;
  username: string;
  avatar: string;
  timeMovingPct: number;
  kills: number;
  kdRatio: number;
  headshotsPct: number;
  deaths: number;
  damage: number;
}

export interface IRosterMatchStat {
  matchId: string;
  placement: number;
  placementPoints: number;
  kills: number;
  score: number;
  createdAt: number;
  players: IRosterStatPlayer[];
}

export type TournamentPrizeType = "CASH" | "CREDIT";
export interface TournamentPrize {
  prizeType: TournamentPrizeType;
  amount: number;
}

export type TournamentPrizeDetails = {
  prizes: TournamentPrize[];
};

export interface TournamentModel {
  id: number;
  title: string;
  teamSize: number;
  gameModeId: string;
  gameModeDisplayName: string;
  description: string;
  startDate: number;
  endDate: number;
  prizePool: number;
  registrationFee: number;
  currency: string;
  imageUrl: string;
  featuredImgUrl: string;
  status: TournamentState;
  checkinOpen: boolean;
  contentId: string;
  duration: string;
  featured: boolean;
  prizeDetails: TournamentPrizeDetails;

  slug: string;
  mode: string;

  ticketStatus?: ITicketStatus;

  schema: { [key: string]: any };
  url: string;
  contents?: IPrismicTournamentDetails;
  prizePoolDisplay: string;
}

/**
 * @deprecated No flow or cases use this at the moment
 * */
export interface IPaymentData {
  creatorCode: string;
  creatorCodeEditable: boolean;
  creatorCodeExpiryDate: number;
  creatorCodeValidTo: number;
}
export interface IValidateCampaignCode {
  discountedPrice: number;
  expiryDate: number;
}

export interface ISearchTournamentsQuery {
  upcoming?: boolean;
  teamSize?: string;
  paid?: boolean;
  page?: number;
  pageSize?: number;
  sort?: string;
  featured?: boolean;
}

export class Tournament {
  private data: TournamentModel;

  constructor(data: TournamentModel) {
    this.data = data;
  }

  destroy(): void {
    return;
  }

  get id(): number {
    return this.data.id;
  }

  get title(): string {
    return this.data.title;
  }

  get teamSize(): number {
    return this.data.teamSize;
  }

  get description(): string {
    return this.data.description;
  }

  get startDate(): number {
    return parseInt((this.data.startDate || 0).toString().padEnd(13, "0")) || 0;
  }

  get endDate(): number {
    return parseInt((this.data.endDate || 0).toString().padEnd(13, "0")) || 0;
  }

  get prizePool(): number {
    return this.data.prizePool;
  }

  get registrationFee(): number {
    return this.data.registrationFee;
  }

  get currency(): string {
    return this.data.currency;
  }

  get slug(): string {
    return this.data.slug;
  }

  get mode(): string {
    return this.data.mode;
  }

  get imageUrl(): string {
    return this.data.imageUrl || "";
  }

  get duration(): string {
    return this.data.duration || "";
  }

  get validTicketCount(): number {
    return this.data.ticketStatus?.validTicket || 0;
  }

  get usedTicketCount(): number {
    return this.data.ticketStatus?.usedTicket || 0;
  }

  get endPurchaseDate(): number {
    /*
     * GAMEF-366 User can still buy ticket even after 10 mins after tournament has started
     */
    return this.startDate + 6e5;
  }

  get endTeamCreation(): number {
    /*
     * GAMEF-366 User can still create team even after 20 mins after tournament has started
     */
    return this.startDate + 12e5;
  }

  get endCheckIn(): number {
    /*
     * GAMEF-366 User can still create team even after 30 mins after tournament has started
     */
    return this.startDate + 18e5;
  }

  get isOpen(): boolean {
    return this.data.status === TournamentState.OPEN;
  }

  get isCancelled(): boolean {
    return this.data.status === TournamentState.CANCELLED;
  }

  get isCompleted(): boolean {
    return this.data.status === TournamentState.COMPLETED;
  }

  get isTournamentGoing(): boolean {
    return this.isStarted && !this.isEnded;
  }

  get isStarted(): boolean {
    const utcNow = moment.utc().valueOf();
    return utcNow > this.startDate;
  }

  get isPurchasable(): boolean {
    const utcNow = moment.utc().valueOf();
    return utcNow <= this.endPurchaseDate && utcNow < this.endDate;
  }

  /**
   * Keep the team registeration opens 20 mins after the tournament starts
   */
  get isTeamRegistrationOpen(): boolean {
    const utcNow = moment.utc().valueOf();
    return utcNow <= this.endTeamCreation && utcNow < this.endDate;
  }

  get isCheckinOpen(): boolean {
    /*
      Checkin opens at 30 mins before tournament started
      and closes at 30 mins after tournament started
    */
    const utcNow = moment.utc().valueOf(),
      openMillis = this.startDate - 18e5,
      closeMillis = this.endCheckIn;

    return openMillis > utcNow && utcNow < closeMillis;
  }

  get isEnded(): boolean {
    const utcNow = moment.utc().valueOf();
    return utcNow > this.endDate;
  }

  get isFree(): boolean {
    return this.registrationFee <= 0;
  }

  get url(): string {
    return this.url;
  }

  get shortStartDate(): string {
    return moment.utc(this.startDate).local().format("DD MMMM yyyy");
  }
}
