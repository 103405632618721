import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "@css/components/common/bell_comp.module.scss";
import RosterInviteNotificationRowComp from "./RosterInviteNotificationRowComp";
import useOnTapElementsCheck from "@app/hooks/useOnTapElementsCheck";
import { IRosterInvitation } from "@models/TeamModel";

interface IBellCompProps {
  rosterInvitations: IRosterInvitation[];
  hasMobileNavbarOpened?: boolean;
}

const BellComp: React.FunctionComponent<IBellCompProps> = (props: IBellCompProps): JSX.Element => {
  const { hasMobileNavbarOpened, rosterInvitations } = props,
    isMounted = useRef(false),
    containerRef = useRef<HTMLDivElement>(null),
    overlayRef = useRef<HTMLDivElement>(null),
    [showOverlay, setShowOverlay] = useState(false),
    [hasNotif, setHasNotif] = useState(false),
    classes = classNames(styles["bell-comp"], {
      [styles["has-notif"]]: hasNotif,
    }),
    btnClasses = classNames("btn btn-circle", styles["btn-bell"], {
      [styles["active"]]: showOverlay,
    }),
    [isContainerTap, isOverlayTap] = useOnTapElementsCheck([containerRef, overlayRef]);

  /** */
  useEffect(() => {
    isMounted.current = true;

    setHasNotif(!!rosterInvitations.length);

    return () => {
      isMounted.current = false;
    };
  }, [rosterInvitations]);

  /** Prevent UI conflict with mobile nav */
  useEffect(() => {
    if (showOverlay && hasMobileNavbarOpened) {
      setShowOverlay(false);
    }
  }, [showOverlay, hasMobileNavbarOpened]);

  useEffect(() => {
    /** On location changed, hide overlay */
    if (showOverlay) {
      setShowOverlay(false);
    }
  }, [window.location]);

  useEffect(() => {
    /** When not tapping inside overlay or bell, hide overlay */
    if (showOverlay && isContainerTap === false && isOverlayTap === false) {
      setShowOverlay(false);
    }
  }, [isContainerTap, isOverlayTap]);

  return (
    <div ref={containerRef} className={classes}>
      <button type="button" className={btnClasses} onClick={() => setShowOverlay(!showOverlay)}>
        <i className="gff gff-notification"></i>
      </button>

      {showOverlay && (
        <div ref={overlayRef} className={classNames("anim-slide-in-right", styles["notif-container"])}>
          <p className={styles["notif-title"]}>Notifications</p>
          <div className={styles["notif-body"]}>
            {rosterInvitations.length ? (
              rosterInvitations.map((rosterInvitation, idx) => {
                return <RosterInviteNotificationRowComp key={idx} className={styles["notification"]} contentClassName={styles["content"]} rosterInvitation={rosterInvitation} />;
              })
            ) : (
              <p className={styles["no-notif"]}>You are awesome</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BellComp;
