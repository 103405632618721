import _ from "lodash";
import { Dispatch } from "react";
import { Middleware } from "redux";
import { useDispatch } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { rootReducer, rootInitialState } from "./ducks";
import { setLocalStorage, getLocalStorage, IS_DEV } from "@app/utilities";

export const logger: Middleware =
  () =>
  (next) =>
  (action): Dispatch<any> => {
    return next(action);
  };

type InitialState = typeof rootInitialState;
type StorageKeys = keyof InitialState;
const STORAGE_KEY = "rs";

/**
 * @description Synchronous mapping mechanism between redux store and localStorage
 * @summary Add more field if there is more thing to sync store between the app and client
 *
 * @field storageKey The state and key that will be used to save the store into localStorage
 * @field storePaths Whitelist of store paths that need to be synced
 */
const storeStorageMap: Partial<{ [key in StorageKeys]: string[] }> = {
    teams: ["virtualTeamByTournamentId"],
  },
  getStatesFromLocalStorage = (): InitialState => {
    const result = _.cloneDeep(rootInitialState);

    if (typeof window !== "undefined" && window.localStorage) {
      const rawData = getLocalStorage(STORAGE_KEY);
      let jsonData = null;

      if (rawData) {
        try {
          jsonData = JSON.parse(rawData);
        } catch (err) {
          if (IS_DEV) {
            console.error("Failed to parse local storage state");
          }

          return result;
        }

        if (jsonData) {
          /** Pick values from "jsonData" to be populated at "result" */
          for (const key in storeStorageMap) {
            const storeKey = key as StorageKeys,
              storePaths = storeStorageMap[storeKey];

            if (storePaths && storePaths.length) {
              for (let i = 0, len = storePaths.length; i < len; i++) {
                const storePath = storePaths[i],
                  storeValue = _.get(jsonData, storeKey + "." + storePath);

                if (storeValue && !_.isEmpty(storeValue) && Object.prototype.hasOwnProperty.call(result, storeKey)) {
                  _.set(result, storeKey + "." + storePath, storeValue);
                }
              }
            }
          }
        }
      }
    }

    return result;
  },
  subscribeToLocalStorage = (theStore: StoreType): (() => void) => {
    const tmpRootState = _.cloneDeep(rootInitialState);

    return () => {
      /**
       * Subscribe to event whenever dispatch is being called
       */
      const rootState = theStore.getState();

      /**
       * Persists value registered in storeStorageMap to localStorage
       */
      if (typeof window !== "undefined" && window.localStorage) {
        for (const key in storeStorageMap) {
          const storeKey = key as StorageKeys;

          if (Object.prototype.hasOwnProperty.call(storeStorageMap, storeKey)) {
            const storePaths = storeStorageMap[storeKey];

            if (storePaths && storePaths.length) {
              for (let i = 0, len = storePaths.length; i < len; i++) {
                const storePath = storePaths[i],
                  storeValue = _.get(rootState, storeKey + "." + storePath);

                if (storeValue && Object.prototype.hasOwnProperty.call(tmpRootState, storeKey)) {
                  _.set(tmpRootState, storeKey + "." + storePath, storeValue);
                }
              }
            }
          }
        }

        /** Remove unneeded keys to reduce storage */
        for (const key in tmpRootState) {
          const storeKey = key as StorageKeys;

          if (Object.prototype.hasOwnProperty.call(tmpRootState, storeKey) && !(storeKey in storeStorageMap)) {
            delete tmpRootState[storeKey];
          }
        }

        setLocalStorage(STORAGE_KEY, JSON.stringify(tmpRootState));
      }
    };
  };

const createStore = (initialState: InitialState, localStorage?: boolean) => {
  if (localStorage) {
    const storageState = getStatesFromLocalStorage();

    /** Merge storageState and initialState */
    initialState = _.merge(storageState, initialState);
  }

  const result = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger),
    devTools: IS_DEV,
    preloadedState: initialState || {},
  });

  if (localStorage) {
    result.subscribe(subscribeToLocalStorage(result));
  }

  return result;
};

const store = createStore({} as InitialState, true);

export type StoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const storeWrapper = createWrapper<EnhancedStore<RootState>>(() => store, {
  debug: false,
});

export { store, createStore };
export default configureStore;
