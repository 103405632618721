const reBot = /((Google|Apple|bing|linkedin|duckduck|Yandex)bot|Yahoo|Baiduspider|Teoma|Slurp|google-structured-data-testing-tool)/i;

export const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

export const isBot = (userAgent?: string): boolean => {
  let uA = userAgent || "";

  if (!uA && typeof window !== "undefined") {
    uA = window?.navigator?.userAgent || "";
  }

  return reBot.test(uA);
};

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const schemifyUrl = (url: string): string => {
  if (!/^(http|https):\/\//.test(url)) {
    return `http://${url}`;
  }

  return url;
};

export function setCookie(cname: string, cvalue: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + d.toUTCString();
  if (typeof document !== "undefined") {
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  return;
}

/**
 * Generate a random number between min (inclusive) and max (inclusive)
 * @returns A new random number
 */
export function generateRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
/**
 * Generate a random numbers between min (inclusive) and max (inclusive)
 * @returns An array of unique random numbers
 */
export function generateUniqueRandomNumbers(min: number, max: number, total: number): number[] {
  const arr = [];

  for (let i = min; i <= max; i++) {
    arr.push(i);
  }

  // Shuffle array
  arr.sort(() => Math.random() - 0.5);

  return arr.slice(0, total);
}

export function generateRandomId(length: number): string {
  const result = [],
    characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }

  return result.join("");
}

export function getCookie(cname: string): string {
  if (typeof document === "undefined") {
    return "";
  }

  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function setLocalStorage(key: string, value: string): void {
  if (typeof window !== "undefined" && window && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
}

export function getLocalStorage(key: string): string {
  if (typeof window !== "undefined" && window && window.localStorage) {
    const res = window.localStorage.getItem(key);
    return res;
  }

  return "";
}

export function getTournamentIdFromSlug(slug: string): number {
  if (slug) {
    // Remove query strings
    slug = slug.replace(/\?.+$/, "");
    return parseInt(slug.split("-").pop() as string) || 0;
  }

  return 0;
}

export function formatCurrency(number: number, currency = "USD", minFractionDigit?: number, maxFractionDigit?: number): string {
  return (number || 0).toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: minFractionDigit || 0,
    maximumFractionDigits: maxFractionDigit || 0,
  });
}

export function formatNumber(number: number, decimals = 0): string {
  const effect = [1, 10, 100, 1e3, 1e4, 1e5, 1e6, 1e7, 1e8, 1e9, 1e10];
  const roundedNumber = Math.round(number * effect[decimals]) / effect[decimals];

  return (roundedNumber || 0).toLocaleString("en-US", {
    minimumFractionDigits: decimals || 0,
  });
}

export function toSlug(text: string): string {
  text = text.replace(/^\s+|\s+$/g, ""); // trim
  text = text.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;",
    to = "aaaaeeeeiiiioooouuuunc------";

  for (let i = 0, len = from.length; i < len; i++) {
    text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  text = text
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return text;
}

export function toDataURL(url: string): Promise<string | ArrayBuffer | null> {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (): void => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export function parseQueryString(input: string, isInputEncoded = true): { [key: string]: any } {
  return (input || "")
    .replace("?", "")
    .split("&")
    .reduce((acc: { [key: string]: any }, currentValue: string) => {
      const [key, value] = currentValue.split("=");

      if (key && value) {
        acc[key] = isInputEncoded ? decodeURIComponent(value) : value;
      }

      return acc;
    }, {});
}

export function sanitizeQueryString(input: string): string {
  return (input || "")
    .replace(/[^a-zA-Z0-9]/gi, " ")
    .replace(/\s+/g, " ")
    .trim();
}

export function addOrdinalSuffix(num: number): string {
  const j = num % 10,
    k = num % 100;

  if (j === 1 && k !== 11) {
    return num + "st";
  }

  if (j === 2 && k !== 12) {
    return num + "nd";
  }

  if (j === 3 && k !== 13) {
    return num + "rd";
  }

  return num + "th";
}

export const sliceText = (text: string, maxLength = 160, ellipsisText = "..."): string => {
  if (text) {
    if (text.length >= maxLength) {
      const result = [],
        words = text.trim().split(" "),
        ellipsisLength = ellipsisText.length;

      for (let i = 0, totalChars = 0, len = words.length; i < len; i++) {
        const word = words[i],
          wordLength = word.length;

        if (totalChars + wordLength < maxLength - ellipsisLength) {
          result.push(word);
          totalChars += wordLength + 1;
        } else {
          break;
        }
      }

      if (result.length) {
        return result.join(" ") + ellipsisText;
      } else {
        return text || "";
      }
    } else {
      return text;
    }
  }

  return "";
};

export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  // Directly return the joined string
  return splitStr.join(" ");
};
