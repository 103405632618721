import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { UserInfo, PlatformAbbr, IUserPlatform } from "@models/UserModel";
import { UserActions } from "./actions";

export interface IUserState {
  data?: UserInfo;
  isFetching: boolean;
}

export const initialState: IUserState = {
  data: null,
  isFetching: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /** Auth */
    builder.addCase(UserActions.login.pending, (userState: IUserState) => {
      userState.isFetching = true;
    });

    builder.addCase(UserActions.login.rejected, (userState: IUserState) => {
      userState.isFetching = false;
    });

    builder.addCase(UserActions.cognitoCheckUser.pending, (userState: IUserState) => {
      userState.isFetching = true;
    });

    builder.addCase(UserActions.cognitoLogin.pending, (userState: IUserState) => {
      userState.isFetching = true;
    });

    builder.addCase(UserActions.cognitoLogin.rejected, (userState: IUserState) => {
      userState.isFetching = false;
    });

    builder.addCase(UserActions.cognitoSignUp.pending, (userState: IUserState) => {
      userState.isFetching = true;
    });

    builder.addCase(UserActions.cognitoSignUp.rejected, (userState: IUserState) => {
      userState.isFetching = false;
    });

    builder.addCase(UserActions.login.fulfilled, (userState: IUserState, { payload }) => {
      const userInfo = payload as UserInfo;
      userState.data = userInfo;
      userState.isFetching = false;
    });

    builder.addCase(UserActions.setProfileInfo.fulfilled, (userState: IUserState, { payload }) => {
      const userInfo = payload as UserInfo;
      userState.data = userInfo;
      userState.isFetching = false;
    });

    builder.addCase(UserActions.cognitoLogin.fulfilled, (userState: IUserState, { payload }) => {
      const userInfo = payload as UserInfo;
      userState.data = userInfo;
      userState.isFetching = false;
    });

    builder.addCase(UserActions.cognitoCheckUser.fulfilled, (userState: IUserState) => {
      userState.isFetching = false;
    });

    builder.addCase(UserActions.cognitoSignUp.fulfilled, (userState: IUserState, { payload }) => {
      const userInfo = payload as UserInfo;
      userState.data = userInfo;
      userState.isFetching = false;
    });

    builder.addCase(UserActions.cognitoCheckUser.rejected, (userState: IUserState) => {
      userState.isFetching = false;
    });

    /** Logout User */
    builder.addCase(UserActions.logout.pending, (userState: IUserState) => {
      userState.isFetching = true;
    });

    builder.addCase(UserActions.logout.rejected, (userState: IUserState) => {
      userState.data = null;
      userState.isFetching = false;
    });

    builder.addCase(UserActions.logout.fulfilled, (userState: IUserState) => {
      userState.data = null;
      userState.isFetching = false;
    });

    /** Profile */
    builder.addCase(UserActions.setCodProfile.fulfilled, (userState: IUserState, { payload }) => {
      if (payload) {
        const { platform: platformAbbr, platform_id: platformId } = payload;

        if (platformAbbr && platformId) {
          const userPlatform = _.find(userState.data?.platforms, {
            platform: platformAbbr,
          }) as IUserPlatform;

          if (userPlatform) {
            userPlatform.platform = platformAbbr as PlatformAbbr;
            userPlatform.platform_id = platformId;
          } else {
            userState.data?.platforms.push({
              platform: platformAbbr as PlatformAbbr,
              platform_id: platformId,
            });
          }
        }
      }
    });
  },
});

// Actions generated from the slice
// export const { } = userSlice.actions;

// The reducer
const userReducer = userSlice.reducer;
export default userReducer;
