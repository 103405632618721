import { createAsyncThunk } from "@reduxjs/toolkit";
import { CognitoUser } from "@aws-amplify/auth";
import { HttpError } from "@app/http/base";
import UserHttp from "@app/http/UserHttp";
import CognitoHttp from "@app/http/CognitoHttp";
import { IPostLoginAttributeOverrides, ISetUserProfile } from "@models/UserModel";

export const UserActions = {
  login: createAsyncThunk("user/login", async (payload: { cognitoUser: CognitoUser; attributeOverrides?: IPostLoginAttributeOverrides }, thunkAPI) => {
    const { cognitoUser, attributeOverrides } = payload;
    return UserHttp.postLogin(cognitoUser, attributeOverrides)
      .then((userInfo) => {
        return userInfo;
      })
      .catch((err: HttpError) => {
        return thunkAPI.rejectWithValue(err);
      });
  }),
  cognitoCheckUser: createAsyncThunk("user/cognitoCheckUser", async (payload: any, thunkAPI) => {
    return CognitoHttp.getAuthenticatedUser()
      .then((cognitoUser) => {
        return cognitoUser;
      })
      .catch((err: HttpError) => {
        return thunkAPI.rejectWithValue(err);
      });
  }),
  cognitoLogin: createAsyncThunk("user/cognitoLogin", async (payload: { email: string; password: string }, thunkAPI) => {
    const { email, password } = payload;
    return CognitoHttp.signIn(email, password)
      .then((cognitoUser) => {
        return UserHttp.postLogin(cognitoUser).then((userInfo) => {
          return userInfo;
        });
      })
      .catch((err: HttpError) => {
        return thunkAPI.rejectWithValue(err);
      });
  }),
  cognitoSignUp: createAsyncThunk("user/cognitoSignUp", async (payload: { transformedEmail: string; verificationCode: string; email: string; password: string }, thunkAPI) => {
    const { transformedEmail, verificationCode, email, password } = payload;
    return CognitoHttp.confirmSignUp(transformedEmail, verificationCode)
      .then(() => {
        return CognitoHttp.signIn(email, password).then((cognitoUser) => {
          return UserHttp.postLogin(cognitoUser).then((userInfo) => {
            return userInfo;
          });
        });
      })
      .catch((err: HttpError) => {
        return thunkAPI.rejectWithValue(err);
      });
  }),
  logout: createAsyncThunk<Boolean, void, {}>("user/logout", async () => {
    return CognitoHttp.signOut()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }),
  setProfileInfo: createAsyncThunk("user/setProfileInfo", async (payload: { profileData?: ISetUserProfile }, thunkAPI) => {
    const { profileData } = payload;
    return UserHttp.setProfileInfo(profileData)
      .then((userInfo) => {
        return userInfo;
      })
      .catch((err: HttpError) => {
        return thunkAPI.rejectWithValue(err);
      });
  }),
  setCodProfile: createAsyncThunk(
    "user/setCodProfile",
    (
      args: {
        platform_long: string;
        platform_id: string;
      },
      thunkAPI
    ) => {
      const { platform_long, platform_id } = args;
      return UserHttp.setCodProfile(platform_long, platform_id)
        .then((data) => {
          return data;
        })
        .catch((err: HttpError) => {
          return thunkAPI.rejectWithValue(err);
        });
    }
  ),
};
