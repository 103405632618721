import { FB_PIXEL_ID, IS_DEV } from "@app/utilities/constants";
import { Router } from "next/router";
import { useEffect } from "react";

export default function FbPixelWorker(): JSX.Element | null {
  let ReactPixel;

  useEffect(() => {
    /**
     * onRouteChangeCompleted
     */
    const onRouteChangeCompleted = () => {
      if (ReactPixel?.pageView && typeof ReactPixel.pageView === "function") {
        ReactPixel.pageView();
      }
    };

    import("react-facebook-pixel").then(({ default: reactPixel }) => {
      ReactPixel = reactPixel;

      /**
       * Initialize Facebook Pixel
       */
      ReactPixel.init(FB_PIXEL_ID, null, {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: IS_DEV, // enable logs
      });

      /**
       * Send pageview when page first loaded
       */
      ReactPixel.pageView();
      return reactPixel;
    });

    Router.events.on("routeChangeComplete", onRouteChangeCompleted);

    return () => {
      Router.events.off("routeChangeComplete", onRouteChangeCompleted);
    };
  }, []);

  return null;
}
