import Logger from "@app/utilities/logger";
import { CognitoUser, Auth } from "@aws-amplify/auth";
import { FederatedSignInOptions } from "@aws-amplify/auth/lib-esm/types";
import { ICredentials } from "@aws-amplify/core";
import { CodeDeliveryDetails, CognitoUserSession, ISignUpResult } from "amazon-cognito-identity-js";
import { HttpError } from "./base";

const cognitoHttp = {
  getAuthenticatedUser: (): Promise<CognitoUser> => {
    return Auth.currentAuthenticatedUser().catch((err) => {
      const httpError = new HttpError(err?.code || "cognito-user-failed", err.message, [], 400);
      Logger.error(httpError, {
        tags: {
          component: "cognito",
        },
        contexts: {
          params: {
            info: "Get current authenticated user",
          },
        },
      });
      throw httpError;
    });
  },
  signUp: (email: string, transformedEmail: string, password: string, username: string, dob: string, phone: string) => {
    /**
     * Standard attributes for AWS cognito
     * @see https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
     */
    const userAttributes = {
      email,
      "custom:preferred_username": username,
    };

    if (phone) {
      // optional - E.164 number convention
      Object.assign(userAttributes, {
        phone_number: phone,
      });
    }

    if (dob) {
      // optional
      Object.assign(userAttributes, {
        birthdate: dob,
      });
    }

    return Auth.signUp({
      username: transformedEmail,
      password,
      attributes: userAttributes,
    }).catch((err) => {
      const httpError = new HttpError(err?.code || "sign-up-failed", err.message, [], 400);
      Logger.error(httpError, {
        tags: {
          component: "cognito",
        },
        contexts: {
          params: {
            email,
            transformedEmail,
            username,
            dob,
            phone,
          },
        },
      });
      throw httpError;
    });
  },
  confirmSignUp: (transformedEmail: string, code: string): Promise<boolean> => {
    return Auth.confirmSignUp(transformedEmail, code, { forceAliasCreation: false })
      .then((res) => {
        if (res === "SUCCESS") {
          return true;
        }

        throw new Error("Code verification failed.");
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "confirm-sign-up-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              transformedEmail,
              code,
            },
          },
        });
        throw httpError;
      });
  },
  signIn: (username: string, password: string): Promise<CognitoUser> => {
    return Auth.signIn({
      username,
      password,
    })
      .then((cognitoUser: CognitoUser) => {
        return cognitoUser;
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "sign-in-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              username,
            },
          },
        });
        throw httpError;
      });
  },
  signOut: (): Promise<any> => {
    return Auth.signOut({
      global: false,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "sign-out-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
        });
        throw httpError;
      });
  },
  forgotPassword: (email: string): Promise<CodeDeliveryDetails> => {
    return Auth.forgotPassword(email)
      .then((result: CodeDeliveryDetails) => {
        return result;
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "forgot-password-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              email,
            },
          },
        });
        throw httpError;
      });
  },
  forgotPasswordSubmit: (email: string, code: string, newPassword: string): Promise<boolean> => {
    return Auth.forgotPasswordSubmit(email, code, newPassword)
      .then((result: string) => {
        return result === "SUCCESS";
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "forgot-password-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              email,
              code,
            },
          },
        });
        throw httpError;
      });
  },
  changePassword: (user: CognitoUser, oldPassword: string, newPassword: string): Promise<boolean> => {
    return Auth.changePassword(user, oldPassword, newPassword)
      .then((result: string) => {
        return result === "SUCCESS";
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "change-password-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              username: user.getUsername(),
            },
          },
        });
        throw httpError;
      });
  },
  refreshSession: (user: CognitoUser): Promise<CognitoUserSession> => {
    const currentSession = user.getSignInUserSession();
    return new Promise((resolve, reject) => {
      user.refreshSession(currentSession.getRefreshToken(), (err, newSession: CognitoUserSession) => {
        if (err) {
          return reject(err);
        }

        return resolve(newSession);
      });
    })
      .then((newSession: CognitoUserSession) => {
        return newSession;
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "refresh-session-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              username: user.getUsername(),
            },
          },
        });
        throw httpError;
      });
  },

  federatedSignIn: (params: FederatedSignInOptions): Promise<ICredentials> => {
    return Auth.federatedSignIn(params)
      .then((credentials: ICredentials) => {
        return credentials;
      })
      .catch((err) => {
        const httpError = new HttpError(err?.code || "federated-sign-in-failed", err.message, [], 400);
        Logger.error(httpError, {
          tags: {
            component: "cognito",
          },
          contexts: {
            params: {
              provider: params.provider,
            },
          },
        });
        throw httpError;
      });
  },

  setNewEmail: async (email?: string): Promise<Boolean> => {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

    try {
      await Auth.updateUserAttributes(cognitoUser, { email });
      return true;
    } catch (err) {
      Logger.error(err, {
        tags: {
          component: "cognito",
          flow: "set-new-email",
        },
        contexts: {
          params: {
            email,
          },
        },
      });
      return false;
    }
  },

  verifyNewEmail: async (code?: string): Promise<Boolean> => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
      return true;
    } catch (err) {
      Logger.error(err, {
        tags: {
          component: "cognito",
        },
        contexts: {
          params: {
            code,
          },
        },
      });
      return false;
    }
  },

  signInSignUp: (email: string, transformedEmail: string, password: string, username: string, dob: string, phone: string): Promise<CognitoUser | ISignUpResult> => {
    return cognitoHttp.signUp(email, transformedEmail, password, username, dob, phone);
    /*
    return Auth.signIn({
      username: email,
      password,
    })
      .then((cognitoUser: CognitoUser) => {
        return cognitoUser;
      })
      .catch((err) => {
        console.log({ err });
        if (err.name === "AuthError" && /sign in types/i.test(err.message)) {
          return cognitoHttp.signUp(email, transformedEmail, password, username, dob, phone);
        } else {
          const httpError = new HttpError(err?.code || "sign-in-failed", err.message, [], 400);
          Logger.error(httpError, {
            tags: {
              component: "cognito",
              flow: "sign-in-sign-up",
            },
            contexts: {
              params: {
                username,
              },
            },
          });
          throw httpError;
        }
      });
    */
  },
};

export default cognitoHttp;
