import { useEffect } from "react";
import { useAppDispatch } from "@app/state/store";
import { IRosterInvitation } from "@models/TeamModel";
import { TeamsAction } from "@ducks/teams/actions";
import { withUserInfo, IWithUserInfo } from "./hocs/withUserInfo";

type INotificationWorker = IWithUserInfo;

function NotificationWorker(props: INotificationWorker) {
  const dispatch = useAppDispatch(),
    { userInfo: userData } = props,
    isAuthenticated = !!userData;

  /** Hook to poll pending invitations */
  useEffect(() => {
    let isMounted = true,
      timer = 0;

    const fetchPendingInvitation = () => {
      if (isAuthenticated && isMounted) {
        return dispatch(TeamsAction.getPendingInvitations(null))
          .then(({ type, payload }) => {
            if (type === TeamsAction.getPendingInvitations.rejected.toString()) {
              throw payload;
            }

            const data = payload as IRosterInvitation[];
            return data.length;
          })
          .catch(() => {
            if (timer) {
              window.clearInterval(timer);
            }
            return 0;
          });
      }
    };

    fetchPendingInvitation();
    timer = window.setInterval(fetchPendingInvitation, 6e4);

    return () => {
      if (timer) {
        window.clearInterval(timer);
      }

      isMounted = false;
    };
  }, [isAuthenticated]);

  return null;
}

export default withUserInfo(NotificationWorker);
