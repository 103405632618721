import React from "react";
import Link from "next/link";
import { DISCORD_INVITATION_LINK, SOCIAL_FACEBOOK, SOCIAL_IG, SOCIAL_TWITTER } from "@app/utilities/constants";

import logo_gamefaced from "@assets/images/logo-gamefaced.png";
import logo from "@assets/images/logo.png";

const FooterComp: React.FunctionComponent = (): JSX.Element => {
  return (
    <footer className="footer-comp font-play">
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <Link href="/" shallow={true}>
          <a aria-label="GameFaced" aria-hidden="false" className="logo d-block mb">
            <img className="d-block gameface-logo mx-auto mb-3" src={logo_gamefaced.src} alt="GameFaced" width={50} height={56} />
            <img className="d-block logo-name mx-auto" src={logo.src} alt="Logo" width={176} height={18} />
          </a>
        </Link>
        <nav className="footer-links mb" role="navigation">
          <Link href="/about/privacy" shallow={true}>
            <a aria-label="Privacy Policy" aria-hidden="false" className="link">
              Privacy policy
            </a>
          </Link>
          <Link href="/about/terms" shallow={true}>
            <a aria-label="Terms of Use" aria-hidden="false" className="link">
              Terms of Service
            </a>
          </Link>
          <Link href="/faqs" shallow={true}>
            <a aria-label="FAQ" aria-hidden="false" className="link">
              Faq
            </a>
          </Link>
          <Link href="/about/support" shallow={true}>
            <a aria-label="Support" aria-hidden="false" className="link">
              Support
            </a>
          </Link>
        </nav>
        <div className="social-links mb w-100 d-flex flex-row justify-content-between">
          <a aria-label="Facebook" aria-hidden="false" href={SOCIAL_FACEBOOK} className="btn btn-circle btn-social" target="_blank" rel="noopener noreferrer">
            <i className="gff gff-facebook-squared"></i>
          </a>
          <a aria-label="Instagram" aria-hidden="false" href={SOCIAL_IG} className="btn btn-circle btn-social" target="_blank" rel="noopener noreferrer">
            <i className="gff gff-instagram"></i>
          </a>
          <a aria-label="Twitter" aria-hidden="false" href={SOCIAL_TWITTER} className="btn btn-circle btn-social" target="_blank" rel="noopener noreferrer">
            <i className="gff gff-twitter"></i>
          </a>
          <a aria-label="Discord" aria-hidden="false" href={DISCORD_INVITATION_LINK} className="btn btn-circle btn-social" target="_blank" rel="noopener noreferrer">
            <i className="gff gff-discord"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterComp;
