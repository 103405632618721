import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";

type IActiveLink = {
  label: string;
  href: string;
  exact?: boolean;
  activeClassName?: string;
  shallow?: boolean;
  className?: string;
  children?: JSX.Element;
};

function isUriMatch(currentUri: string, href: string, exact = false) {
  if (exact) {
    return currentUri === href;
  }
  return currentUri.startsWith(href);
}

export const ActiveLink: React.FC<IActiveLink> = (props: IActiveLink) => {
  const { label, href, className, shallow, exact, activeClassName } = props,
    activeClass = activeClassName || "active",
    router = useRouter(),
    classes = classNames(className, {
      [activeClass]: href === "/" ? router.asPath === href : isUriMatch(router.asPath, href, exact),
    });

  return (
    <Link href={href} shallow={!!shallow}>
      <a aria-label={label} className={classes} suppressHydrationWarning>
        {props.children || label}
      </a>
    </Link>
  );
};
