import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { generatePolygon, generateRandomId } from "@app/utilities";
import discord_user_avatar_svg from "@assets/images/user/discord-user-avatar.svg";
import styles from "@css/components/common/hexagon_image_comp.module.scss";

interface IHexagonImageCompProps {
  id?: string;
  src: string;
  size: number;
  gutter?: number;
  className?: string;
}

const HexagonImageComp: React.FunctionComponent<IHexagonImageCompProps> = (props: IHexagonImageCompProps): JSX.Element => {
  const { id, src, size, gutter: extGutter, className: extClassName } = props;
  const [imgSource, setImgSource] = useState(src || discord_user_avatar_svg.src);
  const imageId = id || generateRandomId(7);
  const rad = size / 2;
  const polygonBorderPoints = generatePolygon(6, rad, rad, rad);
  const gutter = extGutter || 0.94;
  const [polygonAvatarPoints, setPolygonAvatarPoints] = useState(generatePolygon(6, rad * gutter, rad, rad));
  const classes = classNames(styles["hexagon-image-comp"], extClassName);

  /**
   * Check if image is loadable
   */
  useEffect(() => {
    let isMounted = true;

    if (imgSource && imgSource !== discord_user_avatar_svg.src) {
      const img = new Image();
      img.src = imgSource;
      img.onerror = () => {
        if (isMounted) {
          setImgSource(discord_user_avatar_svg.src);
          setPolygonAvatarPoints(generatePolygon(6, rad * gutter, rad, rad));
        }

        return;
      };
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={classes}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height={size}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <defs>
          <pattern id={imageId} x="0" y="0" width="100%" height="100%" patternContentUnits="objectBoundingBox">
            <image height="1" width="1" preserveAspectRatio="xMinYMin slice" xlinkHref={imgSource}></image>
          </pattern>
        </defs>

        <polygon className={styles["border"]} points={polygonBorderPoints} fill="transparent"></polygon>
        <polygon className={styles["avatar"]} points={polygonAvatarPoints} fill={`url('#${imageId}')`}></polygon>
      </svg>
    </div>
  );
};

export default HexagonImageComp;
