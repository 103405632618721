import React, { ComponentType } from "react";
import { userSelector } from "@ducks/user/selectors";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/nextjs";
import { UserInfo } from "@models/UserModel";
import { withCognitoUser, IWithCognitoUser } from "./withCognitoUser";

export type IWithUserInfo = {
  userInfo?: UserInfo;
  userInfoFetching?: boolean;
} & IWithCognitoUser;

export function withUserInfo<T extends object>(Component: ComponentType<T>): ComponentType<IWithUserInfo & T> {
  const AppWithUserInfo: React.FC<IWithCognitoUser & T> = (props) => {
    const { cognitoUser } = props,
      { data: userInfo, isFetching } = useSelector(userSelector);

    if (userInfo) {
      Sentry.setContext("User Info", {
        userId: userInfo?.id,
        email: userInfo?.email,
      });

      if (cognitoUser) {
        Sentry.setContext("Cognito Info", {
          username: cognitoUser.getUsername(),
        });
      }
    } else {
      Sentry.setContext("User Info", {});
      Sentry.setContext("Cognito Info", {});
    }

    return <Component userInfo={userInfo} userInfoFetching={isFetching} {...props} />;
  };

  return withCognitoUser(AppWithUserInfo);
}
