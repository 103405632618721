import { getCookie, setCookie } from "@app/utilities/helpers";

export const setAuthorizationHeader = (token?: string): boolean => {
  if (token) {
    setCookie("JWT_TOKEN", token.trim(), 3);
  } else {
    setCookie("JWT_TOKEN", "", -1);
  }

  return true;
};

export const getAuthorizationHeader = (): string => {
  const token = getCookie("JWT_TOKEN") || "";
  return token.trim();
};
