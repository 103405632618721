import React from "react";
import classNames from "classnames";

interface ILoaderProps {
  size: number;
  className?: string;
}

const Loader: React.FunctionComponent<ILoaderProps> = (props: ILoaderProps): JSX.Element => {
  const { size, className: extClassName } = props,
    sizeClass = size > 0 ? `gff-${size}x` : "",
    classes = classNames("gff gff-spinner gff-pulse", sizeClass, extClassName);

  return (
    <div
      style={{
        display: "block",
        textAlign: "center",
      }}
    >
      <i className={classes} aria-hidden="true"></i>
    </div>
  );
};

export default Loader;
