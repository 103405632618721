import { createContext } from "react";
import tournamentHttp from "@app/http/TournamentHttp";
import { TournamentModel } from "@models/TournamentModel";
import { IPagination } from "@models/utils";

export interface INextTournamentsContext {
  nextTournaments: IPagination<TournamentModel>;
}

const initialState: INextTournamentsContext = {
  nextTournaments: {
    totalCount: 0,
    page: 0,
    rowsPerPage: 0,
    data: [],
  },
};

export const NextTournamentsContext = createContext<INextTournamentsContext>(initialState);

export default function NextTournamentsProvider({ children, nextTournaments }: any) {
  return (
    <NextTournamentsContext.Provider
      value={{
        nextTournaments: nextTournaments || {
          totalCount: 0,
          page: 0,
          rowsPerPage: 0,
          data: [],
        },
      }}
    >
      {children}
    </NextTournamentsContext.Provider>
  );
}

export const withNextTournaments = (PageComponent: any, { ssr = true }) => {
  const WithNextTournaments = ({ nextTournaments, ...props }: { nextTournaments: IPagination<TournamentModel>; [key: string]: any }) => {
    return (
      <NextTournamentsProvider nextTournaments={nextTournaments}>
        <PageComponent {...props} />
      </NextTournamentsProvider>
    );
  };

  if (ssr || PageComponent.getInitialProps) {
    WithNextTournaments.getInitialProps = async ({ ctx }: any) => {
      const pageProps = PageComponent.getInitialProps ? await PageComponent.getInitialProps(ctx) : {};
      const [nextTournaments] = await Promise.all([
        tournamentHttp
          .searchTournaments({
            page: 0,
            pageSize: 3,
            upcoming: true,
          })
          .catch(() => {
            return {
              totalCount: 0,
              page: 0,
              rowsPerPage: 0,
              data: [],
            } as IPagination<TournamentModel>;
          }),
      ]);

      return {
        ...pageProps,
        nextTournaments,
      };
    };
  }

  return WithNextTournaments;
};
