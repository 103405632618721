import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dropdown, NavItem } from "react-bootstrap";
import classNames from "classnames";

import { useAppDispatch } from "@app/state/store";

import { userSelector, UserActions } from "@ducks/user";

import { DISCORD_INVITATION_LINK, SOCIAL_FACEBOOK, SOCIAL_IG, SOCIAL_TWITTER } from "@app/utilities";
import Loader from "./common/Loader";

import UserAvatarComp from "./user/UserAvatarComp";
import BellComp from "./common/BellComp";
import useOnTapElementsCheck from "@app/hooks/useOnTapElementsCheck";
import { pendingInvitationsSelector } from "@ducks/teams";

import logo_gamefaced from "@assets/images/logo-gamefaced.png";
import logo from "@assets/images/logo.png";
import { ActiveLink } from "./navs/ActiveLink";

const HeaderComp: React.FC = (): JSX.Element => {
  const { data: userInfo, isFetching: isUserFetching } = useSelector(userSelector);
  const pendingInvitations = useSelector(pendingInvitationsSelector());
  const [isNavbarOpened, setNavbarOpened] = useState(false);
  const dispatch = useAppDispatch();
  const sideNavRef = useRef<HTMLElement>(null);
  const navToggleRef = useRef<HTMLDivElement>(null);
  const [isSidenavTap, isNavToggleTap] = useOnTapElementsCheck([sideNavRef, navToggleRef]);
  const router = useRouter();

  const doLogout = (): void => {
    dispatch(UserActions.logout());
  };

  const toggleNavbar = (): void => {
    setNavbarOpened(!isNavbarOpened);
  };

  useEffect(() => {
    /** On location changed, hide navbar */
    if (isNavbarOpened) {
      setNavbarOpened(false);
    }
  }, [router.pathname]);

  useEffect(() => {
    /** When not tapping inside navbar, hide navbar */
    if (isNavbarOpened && isSidenavTap === false && isNavToggleTap === false) {
      setNavbarOpened(false);
    }
  }, [isSidenavTap, isNavToggleTap]);

  const navbarToggleClasses = classNames("navbar-toggle", {
      // eslint-disable-next-line prettier/prettier
      expanded: isNavbarOpened,
      // eslint-disable-next-line prettier/prettier
      collapsed: !isNavbarOpened,
    }),
    flyingMenuClasses = classNames("d-flex d-lg-none flying-menu", {
      // eslint-disable-next-line prettier/prettier
      show: isNavbarOpened,
    });

  return (
    <header className="header-comp">
      <div className="container-md d-flex px-3 px-lg-4 px-xl-5">
        <div className="left-section d-flex flex-grow-0 align-items-center justify-content-left py-3 py-lg-0">
          <ul className="list-inline m-0 p-0">
            <li className="list-inline-item">
              <Link href="/" shallow={true}>
                <a aria-label="GameFaced" className="img-link d-flex flex-row align-items-center mx-2">
                  <img className="d-block gameface-logo" src={logo_gamefaced.src} alt="GameFace" width={50} height={56} />
                  <img className="d-block ml-1 ml-lg-2 logo-name" src={logo.src} alt="GameFace" width={176} height={18} />
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <nav className="right-section flex-grow-1" role="navigation">
          <ul className="menu list-inline m-0 p-0">
            <li className="list-inline-item">
              <ActiveLink href="/" shallow={true} className="nav-link" label="Home" />
            </li>
            <li className="list-inline-item">
              <ActiveLink href="/warzone/tournaments" className="nav-link" label="Tournaments" />
            </li>
            <li className="list-inline-item">
              <ActiveLink href="/news" shallow={true} className="nav-link" label="News" />
            </li>
          </ul>
        </nav>
        <div className="right-section separator"></div>
        {!!userInfo && (
          <div className="right-section mr-2">
            <BellComp rosterInvitations={pendingInvitations} />
          </div>
        )}
        <div className="right-section">
          {isUserFetching ? (
            <div className="user-info d-flex align-items-center">
              <Loader size={1} />
            </div>
          ) : userInfo ? (
            <div className="user-info d-flex align-items-center">
              <Dropdown className="standard user-dropdown">
                <Dropdown.Toggle id="dropdown-user-name" className="username" as={NavItem}>
                  <UserAvatarComp user={userInfo} />
                  <span className="ml-2">{userInfo.name || "Player"}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <ActiveLink href="/profile/me/settings" className="dropdown-item" shallow={true} label="Settings">
                    <span>
                      <i className="gff gff-wrench"></i> Settings
                    </span>
                  </ActiveLink>
                  <Dropdown.Item onClick={doLogout}>
                    <i className="gff gff-sign-out-alt"></i> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <ul className="menu list-inline m-0 p-0 ml-3">
              <li className="list-inline-item">
                <ActiveLink href="/auth/register" className="nav-link" label="Register" />
              </li>
              <li className="list-inline-item">
                <ActiveLink href="/auth/login" className="nav-link" label="Login" />
              </li>
            </ul>
          )}
        </div>

        {/* Mobile menu */}
        <div className="right-section mobile">
          {!!userInfo && (
            <div className="bell mr-3">
              <BellComp rosterInvitations={pendingInvitations} hasMobileNavbarOpened={isNavbarOpened} />
            </div>
          )}

          <div ref={navToggleRef} className="d-flex d-lg-none burger">
            <button title="Sidenav" type="button" className={navbarToggleClasses} data-target="#navbar-collapse" onClick={toggleNavbar}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>

          <div className={flyingMenuClasses}>
            <nav ref={sideNavRef} className="menu d-flex flex-column justify-content-center align-items-center py-12" role="navigation">
              <ol className="nav list-inline d-flex flex-column text-right">
                <li className="">
                  <ActiveLink href="/" shallow={true} label="Home" />
                </li>
                <li className="">
                  <ActiveLink href="/warzone/tournaments" label="Tournaments" />
                </li>
                <li className="">
                  <ActiveLink href="/news" shallow={true} label="News" />
                </li>
                {!!userInfo && (
                  <>
                    <li className="separator">
                      <hr />
                    </li>
                    <li className="">
                      <ActiveLink href="/profile/me/settings" shallow={true} label="Settings" />
                    </li>
                  </>
                )}
              </ol>

              <div className="mt-7">
                {userInfo ? (
                  <div className="btns-container">
                    <button title="Logout" className="btn btn-danger btn-sm" type="button" onClick={doLogout}>
                      Logout
                    </button>
                  </div>
                ) : (
                  <div className="btns-container">
                    <ActiveLink href="/auth/register" className="btn btn-secondary btn-sm" label="Register" />
                    <ActiveLink href="/auth/login" className="btn btn-primary btn-sm" label="Login" />
                  </div>
                )}
              </div>

              <div className="social-links">
                <a aria-label="Facebook" href={SOCIAL_FACEBOOK} className="btn btn-circle btn-matisse" target="_blank" rel="noopener noreferrer">
                  <i className="gff gff-facebook-squared"></i>
                </a>
                <a aria-label="Instagram" href={SOCIAL_IG} className="btn btn-circle btn-matisse" target="_blank" rel="noopener noreferrer">
                  <i className="gff gff-instagram"></i>
                </a>
                <a aria-label="Twitter" href={SOCIAL_TWITTER} className="btn btn-circle btn-matisse" target="_blank" rel="noopener noreferrer">
                  <i className="gff gff-twitter"></i>
                </a>
                <a aria-label="Discord" href={DISCORD_INVITATION_LINK} className="btn btn-circle btn-matisse" target="_blank" rel="noopener noreferrer">
                  <i className="gff gff-discord"></i>
                </a>
              </div>

              <div className="mt-auto">
                <ActiveLink href="/" shallow={true} label="GameFaced" className="logo d-block mb">
                  <>
                    <img className="d-block gameface-logo mx-auto mb-3" src={logo_gamefaced.src} alt="GameFace" width={50} height={56} />
                    <img className="d-block logo-name mx-auto" src={logo.src} alt="GameFace" width={176} height={18} />
                  </>
                </ActiveLink>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComp;
