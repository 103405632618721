import { combineReducers } from "@reduxjs/toolkit";

import userReducer, { userInitialState } from "./user";
import paymentsReducer, { paymentsInitialState } from "./payments";
import tournamentsReducer, { tournamentsInitialState } from "./tournaments";
import teamsReducer, { teamsInitialState } from "./teams";

export const rootReducer = combineReducers({
  user: userReducer,
  tournaments: tournamentsReducer,
  payments: paymentsReducer,
  teams: teamsReducer,
});

export const rootInitialState = {
  user: userInitialState,
  tournaments: tournamentsInitialState,
  payments: paymentsInitialState,
  teams: teamsInitialState,
};
