import React, { ComponentType, useEffect, useRef, useState } from "react";
import { CognitoUser, Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { isBot } from "@app/utilities";
import { setAuthorizationHeader } from "@ducks/user/utils";
import cognitoHttp from "@app/http/CognitoHttp";
import { useAppDispatch } from "@app/state/store";
import { UserActions } from "@ducks/user";
import Logger from "@app/utilities/logger";

export type IWithCognitoUser = {
  cognitoUser?: CognitoUser;
  cognitoCustomState?: any;
};

export function withCognitoUser<T extends object>(Component: ComponentType<IWithCognitoUser & T>) {
  const AppWithCognitoUser: React.FC<T> = (props) => {
    const [cognitoUser, setCognitoUser] = useState<CognitoUser>(),
      [customState, setCustomState] = useState(null),
      dispatch = useAppDispatch(),
      refreshTimer = useRef(0);

    function updateSession(user: CognitoUser) {
      if (user) {
        // User logs in
        const userSession = user.getSignInUserSession(),
          accessToken = userSession.getAccessToken(),
          accessExpiryMs = (accessToken?.getExpiration() || 0) * 1e3,
          accessJwtToken = accessToken?.getJwtToken();

        setAuthorizationHeader(accessJwtToken || "");

        // Refresh token 15 seconds before expired
        const refreshIntervalMs = accessExpiryMs - Date.now() - 15e3;

        if (refreshTimer.current) {
          window.clearTimeout(refreshTimer.current);
        }

        refreshTimer.current = window.setTimeout(() => {
          /**
           * Refresh user session
           */
          cognitoHttp
            .refreshSession(user)
            .then((newSession) => {
              user.setSignInUserSession(newSession);
              updateSession(user);
            })
            .catch(() => {
              /**
               * Session is no longer valid
               * Possible err.error is `NotAuthorizedException`
               * Sign user out
               */
              dispatch(UserActions.logout()).then(() => {
                setCognitoUser(null);
              });
            });
        }, refreshIntervalMs);
      } else {
        // User logs out
        setAuthorizationHeader();
        if (refreshTimer.current) {
          window.clearTimeout(refreshTimer.current);
        }
      }
    }

    function updateCognitoUser(cognitoUser?: CognitoUser) {
      setCognitoUser(cognitoUser);

      // Sets JWT Auth
      if (cognitoUser) {
        updateSession(cognitoUser);
      } else {
        updateSession(null);
      }
    }

    function checkUser(): Promise<CognitoUser> {
      return new Promise((resolve) => {
        dispatch(UserActions.cognitoCheckUser(null))
          .then(({ type, payload }) => {
            if (type === UserActions.cognitoCheckUser.rejected.toString()) {
              throw payload;
            }

            const cognitoUser = payload as CognitoUser;
            updateCognitoUser(cognitoUser);
            return resolve(cognitoUser);
          })
          .catch(() => {
            return resolve(null);
          });
      });
    }

    /**
     * Listener for Amplify event
     * @param data Event data
     * @see https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
     */
    const listener = ({ payload }) => {
      const { data, event: eventName } = payload || {};

      switch (eventName) {
        case "signIn":
          if (data) {
            updateCognitoUser(data as CognitoUser);
          } else {
            checkUser();
          }
          break;
        case "signOut":
          updateCognitoUser(null);
          break;
        case "customOAuthState":
          if (data) {
            setCustomState(data);
          }
          break;
        case "tokenRefresh":
        default:
          break;
      }
    };

    useEffect(() => {
      const isCrawler = isBot(navigator?.userAgent);

      if (isCrawler) {
        return () => 0;
      }

      checkUser();
      Hub.listen("auth", listener);

      return () => {
        Hub.remove("auth", listener);
      };
    }, []);

    return <Component cognitoUser={cognitoUser} cognitoCustomState={customState} {...props} />;
  };

  return AppWithCognitoUser;
}
