import memCache from "memory-cache";
import { GET } from "@app/utilities/constants";
import { prismicApiHttp } from "./base";
import { IPrismicTournamentDetails } from "@models/TournamentModel";

const tournamentDetailsCache = new memCache.Cache<string, IPrismicTournamentDetails>();

const prismicHttp = {
  tournamentDetails: (uid: string, lang = "en-us"): Promise<IPrismicTournamentDetails> => {
    const result = tournamentDetailsCache.get(uid);

    if (result) {
      return Promise.resolve(result);
    } else {
      const query = `
          {
            tournament(uid: "${uid}", lang: "${lang}") {
              details
              scoring {
                ... on Scoring {
                  content
                }
              }
              rule {
                ... on Rule {
                  content
                }
              }
            }
          }
        `;

      return prismicApiHttp(GET, "/graphql", {
        query,
      }).then((qRes) => {
        const data = qRes?.data?.data || {};
        if (data.tournament) {
          const ret = {
            details: data.tournament.details || [],
            rule: data.tournament.rule?.content || [],
            scoring: data.tournament.scoring?.content || [],
          } as IPrismicTournamentDetails;

          // Cache prismic data for the next 15 minutes.
          tournamentDetailsCache.put(uid, ret, 6e4 * 15);

          return ret;
        }

        return {
          details: [],
          rule: [],
          scoring: [],
        } as IPrismicTournamentDetails;
      });
    }
  },
};

export default prismicHttp;
