import React, { useMemo } from "react";
import Link from "next/link";
import classNames from "classnames";
import styles from "@css/components/common/notification_row_comp.module.scss";
import HexagonImageComp from "./HexagonImageComp";
import { toSlug } from "@app/utilities";
import { IRosterInvitation } from "@models/TeamModel";

interface INotificationProps {
  onClick?: () => any;
  className?: string;
  contentClassName?: string;
}

interface IRosterNotificationProps extends INotificationProps {
  rosterInvitation: IRosterInvitation;
}

const RosterInviteNotificationRowComp: React.FunctionComponent<IRosterNotificationProps> = (props: IRosterNotificationProps): JSX.Element => {
  const { rosterInvitation, className: extClassName, contentClassName: extContentClassName } = props,
    fromName = rosterInvitation.captain.name,
    fromAvatar = rosterInvitation.captain.avatar || "",
    rosterName = rosterInvitation.roster.rosterName,
    tournamentId = rosterInvitation.tournament.id,
    tournamentTitle = rosterInvitation.tournament.title,
    tournamentSlug = toSlug(tournamentTitle || "") + "-" + tournamentId;

  return useMemo(
    () => (
      <Link
        shallow={true}
        href={{
          pathname: `/tournaments/${tournamentSlug}`,
          search: "?tab=team&refresh=1",
        }}
      >
        <a aria-label={`Team invitation from ${fromName}`} aria-hidden="false" className={classNames(styles["notification-row-comp"], styles["roster"], extClassName)}>
          <div className={classNames(styles["content"], extContentClassName)}>
            <HexagonImageComp src={fromAvatar} size={40} />
            <div className={classNames(styles["message"])}>
              <strong>{fromName}</strong> invited you to team <strong>{rosterName}</strong> for <strong>{tournamentTitle}</strong>
            </div>
          </div>
        </a>
      </Link>
    ),
    [fromName, fromAvatar, rosterName, tournamentTitle, tournamentSlug, extClassName, extContentClassName]
  );
};

export default RosterInviteNotificationRowComp;
