import { IRoster, IRosterInvitation, IUserSearchResult, IVirtualRoster } from "@models/TeamModel";
import { TournamentModel } from "@models/TournamentModel";
import TournamentMappers from "./TournamentMappers";

const TeamMappers = {
  generateVirtualTeam: (tournament: TournamentModel): IVirtualRoster => {
    return {
      tournamentId: tournament.id,
      rosterId: 0,
      rosterName: "",
      rosterCaptain: null,
      rosterMembers: [],
      status: "pending",
      tier: 0,
      skillLevel: 0,
    } as IVirtualRoster;
  },

  mapRosterInvitations: (payload: any): IRosterInvitation[] => {
    const result: IRosterInvitation[] = [];

    for (let i = 0, len = payload && payload.length; i < len; i++) {
      const rawData = payload[i];

      if (rawData) {
        result.push({
          tournament: {
            id: rawData.tournament_id || 0,
            title: rawData.tournament_name || "",
            startDate: rawData.startDate || 0,
            endDate: rawData.endDate || 0,
          },
          roster: {
            rosterId: rawData.roster_id || 0,
            rosterName: rawData.roster_name || "",
          },
          captain: {
            id: rawData.captain_user_id || 0,
            name: rawData.captain_username || "",
            avatar: rawData.captain_avatar || "",
          },
        });
      }
    }

    return result;
  },

  mapUserSearchResult: (payload: any): IUserSearchResult => {
    const result: IUserSearchResult = {
      totalCount: 0,
      users: [],
    };

    if (payload && payload.users && payload.users.length) {
      result.totalCount = payload.total_count || 0;

      for (let i = 0, len = payload.users.length; i < len; i++) {
        const user = payload.users[i];
        result.users.push({
          userId: user.user_id,
          username: user.username,
          discriminator: user.discriminator,
          name: [user.username, user.discriminator].filter(Boolean).join("#"),
          avatar: user.avatar_url,
          status: (user.status || "valid").toLowerCase(),
        });
      }
    }

    return result;
  },

  mapRosterCreationResult: (virtualRoster: IVirtualRoster, payload: any): IRoster => {
    const roster = TournamentMappers.mapRoster({
      tournament_id: virtualRoster.tournamentId,
      roster_captain: payload?.captain,
      roster_members: payload?.members,
      ...payload,
    });

    if (virtualRoster.rosterCaptain) {
      roster.rosterCaptain = {
        rosterId: roster.rosterCaptain.rosterId,
        userId: roster.rosterCaptain.userId,
        platform: roster.rosterCaptain.platform,
        status: roster.rosterCaptain.status,
        username: virtualRoster.rosterCaptain.username,
        avatar: virtualRoster.rosterCaptain.avatar,
        gamesPlayed: virtualRoster.rosterCaptain.gamesPlayed,
        kdRatio: virtualRoster.rosterCaptain.kdRatio,
      };
    }

    for (let i = 0, len = roster?.rosterMembers?.length; i < len; i++) {
      const virtualRosterMember = virtualRoster.rosterMembers.find((member) => member.userId === roster.rosterMembers[i].userId);

      if (virtualRoster.rosterMembers?.length && virtualRosterMember) {
        roster.rosterMembers[i] = {
          rosterId: roster.rosterMembers[i].rosterId,
          userId: roster.rosterMembers[i].userId,
          platform: roster.rosterMembers[i].platform,
          status: roster.rosterMembers[i].status,
          username: virtualRosterMember.username,
          avatar: virtualRosterMember.avatar,
          gamesPlayed: virtualRosterMember.gamesPlayed,
          kdRatio: virtualRosterMember.kdRatio,
        };
      }
    }

    return roster;
  },
};

export default TeamMappers;
