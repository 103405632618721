import { createAsyncThunk } from "@reduxjs/toolkit";
import TournamentHttp from "@app/http/TournamentHttp";
import { HttpError } from "@app/http/base";
import { getAuthorizationHeader } from "@ducks/user/utils";
import { IPrismicTournamentDetails, ITicketStatus, TournamentModel } from "@models/TournamentModel";
import prismicHttp from "@app/http/PrismicHttp";

export const TournamentActions = {
  fetchTournamentById: createAsyncThunk("tournaments/fetchTournamentById", (tournamentId: number, thunkAPI) => {
    return TournamentHttp.fetchSingleTournamentById(tournamentId)
      .then((data) => {
        if (getAuthorizationHeader()) {
          return TournamentHttp.getTicketStatus(data.id).then((ticketStatus) => {
            data.ticketStatus = {
              validTicket: ticketStatus.validTicket,
              usedTicket: ticketStatus.usedTicket,
            } as ITicketStatus;

            return data;
          });
        }

        return data;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error.message);
      });
  }),

  fetchTournamentContent: createAsyncThunk("tournaments/fetchTournamentContent", (tournamentModel: TournamentModel, thunkAPI) => {
    if (tournamentModel?.contentId) {
      return prismicHttp
        .tournamentDetails(tournamentModel.contentId)
        .then((payload) => {
          const contents = payload as IPrismicTournamentDetails,
            tmpTournamentModel = Object.assign({}, tournamentModel);

          tmpTournamentModel.contents = contents;
          return tmpTournamentModel;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error.message);
        });
    }

    return thunkAPI.rejectWithValue("Tournament does not have content.");
  }),

  fetchTournamentTicketStatus: createAsyncThunk("tournaments/fetchTournamentTicketStatus", (tournamentModel: TournamentModel, thunkAPI) => {
    if (tournamentModel && getAuthorizationHeader()) {
      return TournamentHttp.getTicketStatus(tournamentModel.id)
        .then((payload) => {
          const tmpTournamentModel = Object.assign({}, tournamentModel);

          if (tmpTournamentModel && payload && typeof payload === "object") {
            tmpTournamentModel.ticketStatus = payload;
          }

          return tmpTournamentModel;
        })
        .catch((error: HttpError) => {
          return thunkAPI.rejectWithValue(error.message);
        });
    } else {
      return thunkAPI.rejectWithValue("Failed to fetch ticket status when tournament or user does not exist.");
    }
  }),

  admitFreeTicket: createAsyncThunk("tournaments/admitFreeTicket", (arg: { tournamentModel: TournamentModel; campaignCode?: string }, thunkAPI) => {
    const { tournamentModel, campaignCode } = arg;

    return TournamentHttp.admitFreeTicket({
      tournamentId: tournamentModel?.id,
      campaignCode,
    })
      .then((payload) => {
        const tmpTournamentModel = Object.assign({}, tournamentModel);
        if (tmpTournamentModel && payload && typeof payload === "object") {
          tmpTournamentModel.ticketStatus = payload;
        }

        return tmpTournamentModel;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),
};
