function range(count: number): number[] {
  return Array.from(Array(count).keys());
}

function degreesToRadians(angleInDegrees: number): number {
  return (Math.PI * angleInDegrees) / 180;
}

function generatePoints(sideCount: number, radius: number): { theta: number; r: number }[] {
  const angle = 360 / sideCount;
  const vertexIndices = range(sideCount);
  const offsetDeg = 90 - (180 - angle) / 2;
  const offset = degreesToRadians(offsetDeg);

  return vertexIndices.map((index) => {
    return {
      theta: offset + degreesToRadians(angle * index),
      r: radius,
    };
  });
}

function polygon(position: [posX: number, posY: number], sideCount: number, radius: number) {
  const [posX, posY] = position;

  return generatePoints(sideCount, radius)
    .map(({ r, theta }) => [posX + r * Math.cos(theta), posY + r * Math.sin(theta)])
    .join(" ");
}

export function generatePolygon(sideCount: number, radius: number, posX: number, posY: number): string {
  return polygon([posX, posY], sideCount, radius);
}
