import React, { useState, useEffect } from "react";

const useOnTapElementsCheck = (refs: React.RefObject<HTMLElement>[]) => {
  const [state, setState] = useState<boolean[]>([]);

  useEffect(() => {
    const onMousedown = (evt: MouseEvent) => {
      const result = [],
        target = (evt && evt.target) as HTMLElement;

      for (let i = 0, len = refs.length; i < len; i++) {
        const ref = refs[i],
          elem = ref.current;

        if (elem === target || (elem && elem.contains(target))) {
          result.push(true);
        } else {
          result.push(false);
        }
      }

      setState(result);
    };

    document.addEventListener("mousedown", onMousedown);

    return (): void => {
      document.removeEventListener("mousedown", onMousedown);
    };
  }, [refs]);

  return state;
};

export default useOnTapElementsCheck;
