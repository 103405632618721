import { RootState } from "@app/state/store";
import { IRosterInvitation, IVirtualRoster, TeamModel } from "@models/TeamModel";
import { ITeamsState } from "./reducers";

export const teamsSelector = (state: RootState): ITeamsState => state.teams;
export const teamsByTournamentIdSelector =
  (tournamentId: number) =>
  (state: RootState): TeamModel =>
    state.teams.teamByTournamentId[tournamentId] || null;
export const virtualTeamsByTournamentIdSelector =
  (tournamentId: number) =>
  (state: RootState): IVirtualRoster =>
    state.teams.virtualTeamByTournamentId[tournamentId] || null;
export const pendingInvitationsSelector =
  () =>
  (state: RootState): IRosterInvitation[] =>
    state.teams.pendingInvitations || [];
