import React, { useContext } from "react";
import { AppProps } from "next/app";
import { Auth } from "@aws-amplify/auth";

import { storeWrapper } from "@app/state/store";
import { COGNITO_OAUTH_DOMAIN, COGNITO_OAUTH_REDIR_LOGOUT_URL, COGNITO_OAUTH_REDIR_URL, COGNITO_POOL_CLIENT_ID, COGNITO_POOL_ID, COGNITO_REGION, IS_PROD, SOCIAL_IG } from "@app/utilities";
import Layout from "@app/Layout";

import HeaderComp from "@components/HeaderComp";
import FooterComp from "@components/FooterComp";
import ScrollToTop from "@components/utils/ScrollToTop";
import NotificationWorker from "@views/utils/NotificationWorker";
import AuthWorker from "@views/utils/AuthWorker";
import FbPixelWorker from "@views/utils/FbPixelWorker";
import { ToastContainer } from "react-toastify";

import "@css/index.scss";
import { NextTournamentsContext, withNextTournaments } from "@views/utils/hocs/withUpcomingTournaments";
import DiscordLink from "@components/common/DiscordLink";
import { useRouter } from "next/router";

/**
 * Cognito manual configuration
 */
Auth.configure({
  region: COGNITO_REGION,
  userPoolId: COGNITO_POOL_ID,
  userPoolWebClientId: COGNITO_POOL_CLIENT_ID,
  oauth: {
    domain: COGNITO_OAUTH_DOMAIN,
    scope: ["email", "profile", "openid"],
    redirectSignIn: COGNITO_OAUTH_REDIR_URL,
    redirectSignOut: COGNITO_OAUTH_REDIR_LOGOUT_URL,
    responseType: "code",
  },
});

function App({ Component, pageProps }: AppProps): JSX.Element {
  const { nextTournaments } = useContext(NextTournamentsContext),
    router = useRouter();

  const showNoTournamentBox = nextTournaments && nextTournaments.totalCount === 0 && !router.pathname.startsWith("/news");

  return (
    <>
      <HeaderComp />
      <Layout>
        {showNoTournamentBox && (
          <div className="no-tournament-box">
            <div className="content">
              Thank you for visiting the GameFace website! We're currently upgrading our platform to bring you the most advanced battle royale events in the industry. Until we're ready we will not be hosting any tournaments. Follow us on
              Instagram{" "}
              <a aria-label="Instagram" href={SOCIAL_IG} className="" target="_blank" rel="noopener noreferrer">
                @GameFaceGG
              </a>{" "}
              and join our <DiscordLink text="GameFace Warzone Tournaments" /> Discord to stay up-to-date on when we're back as we transition to a new era of casual and competitive gaming.
            </div>
          </div>
        )}
        <Component {...pageProps} />
      </Layout>
      <FooterComp />
      <ScrollToTop />
      <NotificationWorker />
      <AuthWorker />
      <FbPixelWorker />
      <ToastContainer role="alert" autoClose={5e3} position={"bottom-right"} limit={4} theme={"dark"} />
    </>
  );
}

export function reportWebVitals(metric) {
  if (!IS_PROD) {
    const { name, startTime, value, label } = metric;
    console.info(`[${label}]`, `[${name} ${startTime}] - ${parseInt(value)}ms`);
  }
}

export default withNextTournaments(storeWrapper.withRedux(App), { ssr: true });
