import { createSlice } from "@reduxjs/toolkit";
import { TournamentModel } from "@models/TournamentModel";
import { TournamentActions } from "./actions";
import { HYDRATE } from "next-redux-wrapper";

export interface ITournamentsState {
  tournamentsById: { [key: number]: TournamentModel };
  singleTournament: TournamentModel | null;
}

export const initialState: ITournamentsState = {
  tournamentsById: {},
  singleTournament: null,
};

const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {
    setSingleTournament: (state: ITournamentsState, { payload }): void => {
      state.singleTournament = payload as TournamentModel;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (tournamentState: ITournamentsState, action: any) => {
      if (action?.payload?.tournaments) {
        const ssrTournamentsState = action.payload.tournaments as ITournamentsState;
        tournamentState.singleTournament = ssrTournamentsState.singleTournament;
      }
    });
    builder.addCase(TournamentActions.fetchTournamentById.fulfilled, (tournamentState: ITournamentsState, { payload }) => {
      if (payload) {
        const tournamentModel = payload as TournamentModel;

        if (tournamentModel.id) {
          tournamentState.tournamentsById[tournamentModel.id] = Object.assign({}, tournamentModel);
        }
      }
    });

    builder.addCase(TournamentActions.fetchTournamentContent.fulfilled, (tournamentState: ITournamentsState, { payload }) => {
      if (payload) {
        const tournamentModel = payload as TournamentModel;

        if (tournamentModel.id) {
          tournamentState.tournamentsById[tournamentModel.id] = tournamentModel;
        }
      }
    });

    builder.addCase(TournamentActions.fetchTournamentTicketStatus.fulfilled, (tournamentState: ITournamentsState, { payload }) => {
      if (payload) {
        const tournamentModel = payload as TournamentModel;

        if (tournamentModel.id) {
          tournamentState.tournamentsById[tournamentModel.id] = tournamentModel;
        }
      }
    });

    builder.addCase(TournamentActions.admitFreeTicket.fulfilled, (tournamentState: ITournamentsState, { payload }) => {
      if (payload) {
        const tournamentModel = payload as TournamentModel;

        if (tournamentModel.id) {
          tournamentState.tournamentsById[tournamentModel.id] = tournamentModel;
        }
      }
    });
  },
});

// Actions generated from the slice
export const { setSingleTournament } = tournamentsSlice.actions;

// The reducer
const tournamentsReducer = tournamentsSlice.reducer;
export default tournamentsReducer;
