import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { apiHttp } from "./base";
import { GET, POST, PUT } from "@app/utilities/constants";
import { IRoster, IRosterInvitation, IRosterMember, IUserSearchResult, IVirtualRoster } from "@models/TeamModel";
import TournamentMappers from "./mappers/TournamentMappers";
import TeamMappers from "./mappers/TeamMappers";

const cancelTokens: { [key in keyof typeof teamHttp]?: CancelTokenSource } = {};

const teamHttp = {
  /**
   * Returns public data of given candidate roster user.
   * @see TournamentMappers.mapRosterMember
   * @returns IRosterMember
   */
  getRosterMember: async (userId: string): Promise<IRosterMember> => {
    return apiHttp(GET, `/tournament/candidateRoster/user/${userId}`, null).then((response: AxiosResponse) => {
      return TournamentMappers.mapRosterMember(response.data);
    });
  },

  searchUser: async (tournamentId: number, query: string): Promise<IUserSearchResult> => {
    cancelTokens.searchUser?.cancel();
    cancelTokens.searchUser = axios.CancelToken.source();

    return apiHttp(GET, `/tournament/userSearch/${tournamentId}?query_string=${encodeURIComponent(query)}`, null, {
      cancelToken: cancelTokens.searchUser.token,
    }).then((response: AxiosResponse) => {
      return TeamMappers.mapUserSearchResult(response.data);
    });
  },

  createTeam: async (rosterData: IVirtualRoster): Promise<IRoster> => {
    return apiHttp(POST, `/tournament/createRoster/${rosterData.tournamentId}`, {
      roster_name: rosterData.rosterName,
      captain: rosterData.rosterCaptain?.userId,
      members: rosterData.rosterMembers.map((member) => member.userId),
    }).then((response: AxiosResponse) => {
      return TeamMappers.mapRosterCreationResult(rosterData, response.data);
    });
  },

  unregisterTeam: async (tournamentId: number): Promise<IRoster> => {
    return apiHttp(PUT, `/roster/unregister?tournament_id=${tournamentId}`, null).then((response: AxiosResponse) => {
      return TournamentMappers.mapRoster(response.data);
    });
  },

  getPendingInvitations: async (): Promise<IRosterInvitation[]> => {
    cancelTokens.getPendingInvitations?.cancel();
    cancelTokens.getPendingInvitations = axios.CancelToken.source();

    return apiHttp(GET, `/roster/invitation/pending`, null, {
      cancelToken: cancelTokens.getPendingInvitations.token,
    }).then((response: AxiosResponse) => {
      return TeamMappers.mapRosterInvitations(response.data);
    });
  },

  acceptRosterInvitation: async (tournamentId: number): Promise<IRoster> => {
    return apiHttp(PUT, `/roster/accept?tournament_id=${tournamentId}`, null).then((response: AxiosResponse) => {
      return TournamentMappers.mapRoster(response.data);
    });
  },

  rejectRosterInvitation: async (tournamentId: number): Promise<IRoster> => {
    return apiHttp(PUT, `/roster/reject?tournament_id=${tournamentId}`, null).then((response: AxiosResponse) => {
      return TournamentMappers.mapRoster(response.data);
    });
  },
};

export default teamHttp;
