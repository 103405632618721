import { createSlice } from "@reduxjs/toolkit";
import { IRoster, IRosterInvitation, IVirtualRoster } from "@models/TeamModel";
import { TournamentModel } from "@models/TournamentModel";
import { TeamsAction } from "./actions";

export interface ITeamsState {
  teamByTournamentId: { [tournamentId: number]: IRoster };
  virtualTeamByTournamentId: { [tournamentId: number]: IVirtualRoster };
  pendingInvitations: IRosterInvitation[];
}

export const initialState: ITeamsState = {
  teamByTournamentId: {},
  virtualTeamByTournamentId: {},
  pendingInvitations: [],
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    disbandVirtualTeam: (state: ITeamsState, { payload }): void => {
      if (payload) {
        const tournamentModel = payload as TournamentModel;

        if (Object.prototype.hasOwnProperty.call(state.virtualTeamByTournamentId, tournamentModel.id)) {
          delete state.virtualTeamByTournamentId[tournamentModel.id];
        }
      }

      return;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(TeamsAction.updateVirtualTeam.fulfilled, (teamsState: ITeamsState, { payload }) => {
      if (payload) {
        const virtualTeam = payload as IVirtualRoster;

        if (virtualTeam && virtualTeam.tournamentId) {
          teamsState.virtualTeamByTournamentId[virtualTeam.tournamentId] = virtualTeam;
        }
      }
    });

    builder.addCase(TeamsAction.getActiveRoster.fulfilled, (teamsState: ITeamsState, { payload }) => {
      if (payload) {
        const team = payload as IRoster;

        if (team && team.tournamentId) {
          if (team.status !== "unregistered") {
            teamsState.teamByTournamentId[team.tournamentId] = team;
          } else if (team.status === "unregistered" && Object.prototype.hasOwnProperty.call(teamsState.teamByTournamentId, team.tournamentId)) {
            delete teamsState.teamByTournamentId[team.tournamentId];
          }
        }
      }
    });

    builder.addCase(TeamsAction.createRoster.fulfilled, (teamsState: ITeamsState, { payload }) => {
      if (payload) {
        const team = payload as IRoster;

        if (team && team.tournamentId) {
          teamsState.teamByTournamentId[team.tournamentId] = team;
        }
      }
    });

    builder.addCase(TeamsAction.disbandRoster.fulfilled, (teamsState: ITeamsState, { payload }) => {
      if (payload) {
        const team = payload as IRoster;

        if (Object.prototype.hasOwnProperty.call(teamsState.teamByTournamentId, team.tournamentId)) {
          delete teamsState.teamByTournamentId[team.tournamentId];
        }
      }
    });

    builder.addCase(TeamsAction.getPendingInvitations.fulfilled, (teamsState: ITeamsState, { payload }) => {
      teamsState.pendingInvitations = payload as IRosterInvitation[];
    });

    builder.addCase(TeamsAction.acceptRosterInvitation.fulfilled, (teamsState: ITeamsState, { payload }) => {
      if (payload) {
        const team = payload as IRoster;

        if (team && team.tournamentId) {
          teamsState.teamByTournamentId[team.tournamentId] = team;
        }
      }
    });

    builder.addCase(TeamsAction.rejectRosterInvitation.fulfilled, (teamsState: ITeamsState, { payload }) => {
      if (payload) {
        const team = payload as IRoster;

        if (team && team.tournamentId) {
          teamsState.teamByTournamentId[team.tournamentId] = team;
        }
      }
    });
  },
});

// Actions generated from the slice
export const { disbandVirtualTeam } = teamsSlice.actions;

// The reducer
const TeamsReducer = teamsSlice.reducer;
export default TeamsReducer;
