import _ from "lodash";
import axios, { Method, AxiosPromise, AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { GET, API_PORT, PAPI_PORT, PRISMIC_PORT, IS_PROD } from "@app/utilities";
import { getAuthorizationHeader } from "@ducks/user/utils";
import Prismic from "@prismicio/client";
import { ApiData } from "@prismicio/client/types/ResolvedApi";
import Logger from "@app/utilities/logger";

type IApiHttpConfig = AxiosRequestConfig & {
  noAuthHeader?: boolean;
};
export class HttpError extends Error {
  /**
   * @description The error code describing the error
   */
  error: string;
  entities: any[];
  status: number;

  constructor(error: string, message: string, entities: any[], status: number) {
    super(message);
    this.error = error;
    this.entities = entities || [];
    this.status = status;
  }
}

const baseAxios = (port: string, method: Method, path: string, data?: any, extraConfig?: AxiosRequestConfig): AxiosPromise<any> => {
  let request: AxiosPromise<any> = null;
  const config: AxiosRequestConfig = {
    timeout: 1e4,
    ...extraConfig,
  };

  if (method === GET) {
    request = axios.get(`${port}${path}`, {
      params: data || {},
      ...config,
    });
  } else {
    request = axios({
      method,
      url: `${port}${path}`,
      data,
      ...config,
    });
  }

  return request;
};

export const papiHttp = (method: Method, path: string, data?: any, extraConfig?: AxiosRequestConfig): AxiosPromise<any> =>
  baseAxios(PAPI_PORT, method, path, data, extraConfig)
    .catch((reason: AxiosError) => {
      if (axios.isCancel(reason)) {
        if (!IS_PROD) {
          console.log(`Request cancelled for "${PAPI_PORT}${path}"`);
        }

        throw new HttpError("cancelled", "Client Closed Request", [], 499);
      }

      const httpErrors: HttpError[] = [],
        response = reason.response;

      if (response && response.data) {
        const { error, code, message } = response.data;
        httpErrors.push(new HttpError(error || code, message, [], response?.status || 500));
        throw httpErrors[0];
      }

      const config = reason.config || {};
      throw new HttpError("", `Failed to "${config.method}" on ${config.url}`, [], response?.status || 500);
    })
    .catch((httpError: HttpError) => {
      if (httpError.error !== "cancelled") {
        Logger.error(httpError, {
          tags: {
            component: "axios",
          },
          contexts: {
            params: {
              port: PAPI_PORT,
              method,
              path,
              data,
            },
          },
        });
      }

      throw httpError;
    });

export const apiHttp = (method: Method, path: string, data?: any, extraConfig?: IApiHttpConfig): AxiosPromise<any> => {
  const bearerToken = getAuthorizationHeader(),
    defaultConfig: AxiosRequestConfig = {
      headers: {
        Authorization: bearerToken ? `Bearer ${bearerToken}` : "",
      },
    };

  if (extraConfig?.noAuthHeader) {
    delete defaultConfig?.headers?.Authorization;
  }

  return baseAxios(API_PORT, method, path, data, _.merge(defaultConfig, extraConfig))
    .catch((reason: AxiosError) => {
      if (axios.isCancel(reason)) {
        if (!IS_PROD) {
          console.log(`Request cancelled for "${API_PORT}${path}"`);
        }

        throw new HttpError("cancelled", "Client Closed Request", [], 499);
      }

      const httpErrors: HttpError[] = [],
        response = reason.response;

      if (response?.status === 403) {
        httpErrors.push(new HttpError("Forbidden", "Access Denied", [], 403));
      } else if (response?.data) {
        if (path === "/auth/post-login") {
          // Only for post login error has a model of {result_type, user}
          const { result_type, user } = response.data;
          httpErrors.push(new HttpError(result_type, "Post login has failed", [user], response?.status || 500));
        } else {
          // Generic errors
          const { errors, message: topMessage } = response.data;

          for (let i = 0, len = errors && errors.length; i < len; i++) {
            const { code, message, entities } = errors[i];

            if (code && (message || topMessage)) {
              httpErrors.push(new HttpError(code, message || topMessage, entities || [], response?.status || 500));
            }
          }
        }
      }

      if (httpErrors.length) {
        throw httpErrors[0];
      } else {
        const config = reason.config || {};
        throw new HttpError("", `Failed to "${config.method}" on ${config.url}`, [], response?.status || 500);
      }
    })
    .catch((httpError: HttpError) => {
      if (httpError.error !== "cancelled") {
        Logger.error(httpError, {
          tags: {
            component: "axios",
          },
          contexts: {
            params: {
              port: API_PORT,
              method,
              path,
              data,
              entities: httpError.entities || [],
            },
          },
        });
      }

      throw httpError;
    })
    .then((response: AxiosResponse) => {
      return response;
    });
};

export const prismicClientHttp = Prismic.client(PRISMIC_PORT + "/api/v2", {
  timeoutInMs: 1e4,
});

let prismicHeaders: any = null;
export const prismicApiHttp = (method: Method, path: string, data?: any, extraConfig?: AxiosRequestConfig): AxiosPromise<any> => {
  const headers = extraConfig?.headers || {},
    accessToken = headers?.accessToken || "";

  if (data?.query) {
    data.query = data.query
      .replace(/\r\n|\n|\r/g, "")
      .replace(/\s+/g, " ")
      .replace(/({|})\s*/g, "$1")
      .trim();
  }

  const prismicQuery = () => {
    return axios({
      method,
      url: `${PRISMIC_PORT}${path}`,
      params: data || {},
      headers: prismicHeaders,
    }).catch((err: AxiosError) => {
      const { code, message, response } = err,
        httpError = new HttpError(code, message || "Prismic http error", [], response?.status || 500);

      Logger.error(httpError, {
        tags: {
          component: "axios",
        },
        contexts: {
          params: {
            port: API_PORT,
            method,
            path,
            data,
            responseData: response?.data || {},
          },
        },
      });

      throw httpError;
    });
  };

  if (prismicHeaders && prismicHeaders.authorization) {
    return prismicQuery();
  } else {
    return axios({
      method,
      url: `${PRISMIC_PORT}/api`,
      params: {},
    })
      .then((api: any) => {
        const data = api.data as ApiData,
          masterRef = data.refs.filter((ref) => ref.isMasterRef)[0];

        prismicHeaders = {
          "Prismic-ref": masterRef.ref,
          ...(accessToken ? { authorization: `Token ${accessToken}` } : {}),
        };

        return prismicQuery();
      })
      .catch((err: AxiosError) => {
        const { code, message, response } = err,
          httpError = new HttpError(code, message || "Prismic http error", [], response?.status || 500);

        Logger.error(httpError, {
          tags: {
            component: "axios",
          },
          contexts: {
            params: {
              port: API_PORT,
              method,
              path,
              data,
              responseData: response?.data || {},
            },
          },
        });

        throw httpError;
      });
  }
};
