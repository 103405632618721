import React from "react";
import { DISCORD_INVITATION_LINK } from "@app/utilities";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface IDiscordLinkProps {
  tooltipPlacement?: "top" | "right" | "bottom" | "left";
  text?: string;
}

const DiscordLink: React.FunctionComponent<IDiscordLinkProps> = ({ tooltipPlacement, text }: IDiscordLinkProps): JSX.Element => {
  text = text || "Discord";

  return tooltipPlacement ? (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={
        <Tooltip id={`discord-link-${tooltipPlacement}`}>
          <div className="p-2">Discord is a channel for group chat. We use Discord to create tournament teams, connect friends and keep the party going!</div>
        </Tooltip>
      }
    >
      <a aria-label={text} aria-hidden="false" href={DISCORD_INVITATION_LINK} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </OverlayTrigger>
  ) : (
    <a href={DISCORD_INVITATION_LINK} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

export default DiscordLink;
