import { CognitoUser } from "@aws-amplify/auth";
export type PlatformType = "PlayStation" | "Steam" | "Activision" | "Battle.net" | "XBox" | "Unknown";
export type PlatformAbbr = "psn" | "steam" | "uno" | "battle" | "xbl" | "unk";
export type JoinedGuildType = "joined" | "not-joined" | "error";
export type PostLoginResultType = "USER_CREATED" | "USER_UPDATED" | "USER_MISSING_ATTRIBUTES";
export interface IUserPlatform {
  platform: PlatformAbbr;
  platform_id: string;
}

export interface IPostLoginAttributeOverrides {
  preferred_username: string;
  date_of_birth: string;
  phone_number?: string;
}

export type ISetUserProfile = IPostLoginAttributeOverrides & {
  email?: string;
};

export interface UserInfo {
  id: string;
  email: string;
  username: string;
  discriminator: string;
  phoneNumber: string;
  dateOfBirth: string;
  avatar: string;
  platforms: IUserPlatform[];

  // Extra third-party property;
  cognitoUser?: CognitoUser;

  // Added for backward compatibility
  name?: string;
}

export const PlatformAbbrMap: { [key in PlatformType]: PlatformAbbr } = {
  PlayStation: "psn",
  Steam: "steam",
  Activision: "uno",
  "Battle.net": "battle",
  XBox: "xbl",
  Unknown: "unk",
};

export const PlatformTypeMap: { [key in PlatformAbbr]: PlatformType } = {
  psn: "PlayStation",
  steam: "Steam",
  uno: "Activision",
  battle: "Battle.net",
  xbl: "XBox",
  unk: "Unknown",
};

export const PlatformLabelMap: { [key in PlatformAbbr]: string } = {
  psn: "PlayStation Network",
  steam: "Steam",
  uno: "Activision",
  battle: "Battle.net",
  xbl: "XBox Live",
  unk: "Unknown",
};

export const PlatformPlaceholderMap: { [key in PlatformAbbr]: string } = {
  psn: "Online ID",
  steam: "Steam ID",
  uno: "Activision ID",
  battle: "BattleTag",
  xbl: "Gamertag",
  unk: "Unknown",
};
