import { useRouter } from "next/router";
import { useEffect } from "react";

export default function ScrollToTop(): JSX.Element | null {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, [router.pathname]);

  return null;
}
