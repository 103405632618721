import React, { useEffect, useState } from "react";
import { UserInfo } from "@models/UserModel";
import discord_user_avatar_svg from "@assets/images/user/discord-user-avatar.svg";

interface IUserAvatarCompProps {
  user: UserInfo;
}

const UserAvatarComp: React.FunctionComponent<IUserAvatarCompProps> = (props: IUserAvatarCompProps): JSX.Element => {
  const { user: userInfo } = props;
  const [hasAvatar, setHasAvatar] = useState(false);

  const onAvatarClick = (evt: React.MouseEvent<HTMLAnchorElement>): boolean => {
    evt.preventDefault();
    return false;
  };

  useEffect(() => {
    if (userInfo?.avatar) {
      const avatarImg = new Image();
      avatarImg.src = userInfo.avatar;
      avatarImg.onload = (): void => {
        setHasAvatar(true);
      };
      avatarImg.onerror = (): void => {
        setHasAvatar(false);
      };
    }
  }, [userInfo]);

  return (
    <div id="user-avatar" className="user-avatar-comp">
      <a className={"avatar" + (hasAvatar ? " thumbnail" : " default")} href="/" onClick={onAvatarClick}>
        {hasAvatar ? <img src={userInfo.avatar} alt="Avatar" /> : <img src={discord_user_avatar_svg.src} alt="Avatar" />}
      </a>
    </div>
  );
};

export default React.memo(UserAvatarComp);
