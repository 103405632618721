import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpError } from "@app/http/base";
import teamHttp from "@app/http/TeamHttp";
import TournamentHttp from "@app/http/TournamentHttp";
import { IRoster, IVirtualRoster, TeamModel } from "@models/TeamModel";

export const TeamsAction = {
  /**
   * Returns user's roster status regardless user has a roster or not
   * If the user doesn't have any active roster, "response.rosterId" will be 0 - and others properties will be defaults.
   * @see TournamentMappers.mapRoster
   * @returns TeamModel
   */
  getActiveRoster: createAsyncThunk("teams/getActiveRoster", (tournamentId: number, thunkAPI) => {
    return TournamentHttp.getActiveRoster(tournamentId)
      .then((data) => {
        if (data) {
          return data as TeamModel;
        }

        return null;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),

  updateVirtualTeam: createAsyncThunk("teams/updateVirtualTeam", (team: IVirtualRoster, thunkAPI) => {
    try {
      const virtualTeam = team as IVirtualRoster;

      if (virtualTeam.tournamentId) {
        return virtualTeam;
      }

      return null;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }),

  createRoster: createAsyncThunk("teams/createRoster", (team: IVirtualRoster, thunkAPI) => {
    return teamHttp
      .createTeam(team)
      .then((data) => {
        if (data) {
          return data as IRoster;
        }

        return null;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),

  disbandRoster: createAsyncThunk("teams/disbandRoster", (team: IRoster, thunkAPI) => {
    return teamHttp
      .unregisterTeam(team.tournamentId)
      .then((data) => {
        if (data) {
          return data as IRoster;
        }

        return null;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),

  getPendingInvitations: createAsyncThunk("teams/pendingInvitations", (_: any, thunkAPI) => {
    return teamHttp
      .getPendingInvitations()
      .then((data) => {
        return data;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),

  acceptRosterInvitation: createAsyncThunk("teams/accept", (tournamentId: number, thunkAPI) => {
    return teamHttp
      .acceptRosterInvitation(tournamentId)
      .then((data) => {
        return data;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),

  rejectRosterInvitation: createAsyncThunk("teams/reject", (tournamentId: number, thunkAPI) => {
    return teamHttp
      .rejectRosterInvitation(tournamentId)
      .then((data) => {
        return data;
      })
      .catch((error: HttpError) => {
        return thunkAPI.rejectWithValue(error);
      });
  }),
};
