import { PaymentModel } from "@models/PaymentModel";
import { createSlice } from "@reduxjs/toolkit";

export interface IPaymentsState {
  list: PaymentModel[];
}

export const initialState: IPaymentsState = {
  list: [],
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    noop: (state: IPaymentsState, { payload }): void => {
      return;
    },
  },
});

// Actions generated from the slice
export const { noop } = paymentsSlice.actions;

// The reducer
const paymentsReducer = paymentsSlice.reducer;
export default paymentsReducer;
