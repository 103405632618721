import moment from "moment";
import { formatCurrency, toSlug } from "@app/utilities/helpers";
import { APP_URL } from "@app/utilities/constants";
import { IRosterMatchStat, IRosterStatPlayer, IRosterStatResponse, ITournamentStat, TournamentModel, TournamentState } from "@models/TournamentModel";
import { IRoster, IRosterMember, IRosterStatus, ITournamentRosterData } from "@models/TeamModel";
import codDefaultBanner from "@assets/images/tournaments/cod-tournament-banner-default.png";
import discord_user_avatar_svg from "@assets/images/user/discord-user-avatar.svg";

const TournamentMappers = {
  mapTournamentRostersToStats: (rostersData: ITournamentRosterData[]): ITournamentStat[] => {
    const result: ITournamentStat[] = [];

    for (let i = 0, len = rostersData && rostersData.length; i < len; i++) {
      const rosterData = rostersData[i];
      result.push({
        score: 0,
        tier_no: rosterData.tier,
        roster_id: (rosterData.rosterId || 0).toString(),
        roster_name: rosterData.rosterName,
        top_kills: 0,
        top_placement_points: 0,
        total_match_count: 0,
        disqualified: false,
        flagged: false,
        finalized: false,
      });
    }

    return result;
  },

  mapRosterMatchStats: (resp: IRosterStatResponse): IRosterMatchStat[] => {
    const result = [];

    if (resp.stats?.matches?.length) {
      const matches = resp.stats.matches;
      const userInfos = resp.user_info;
      const userMap: {
        [id: string]: { username: string; avatar: string };
      } = {};

      /** Map user info */
      if (userInfos?.length) {
        for (let i = 0, len = userInfos.length; i < len; i++) {
          const userInfo = userInfos[i];
          userMap[userInfo.gameface_user_id] = {
            username: userInfo.username,
            avatar: userInfo?.avatar_url,
          };
        }
      }

      /** Map the response */
      if (matches?.length) {
        for (let i = 0, len = matches.length; i < len; i++) {
          const match = matches[i],
            mappedMatch: IRosterMatchStat = {
              matchId: match.match_id,
              placement: match.placement,
              placementPoints: match.placement_points,
              kills: match.kills,
              score: match.score,
              createdAt: match.created_at,
              players: [],
            };

          const players = match?.players || [];
          for (let y = 0, len2 = players.length; y < len2; y++) {
            const player = players[y],
              playerUser = userMap[player.user_id],
              mappedPlayer: IRosterStatPlayer = {
                uid: player.user_id,
                username: playerUser?.username,
                avatar: playerUser?.avatar,
                timeMovingPct: player.timeMovingPct,
                kills: player.kills,
                kdRatio: player.kdRatio,
                headshotsPct: player.headshotsPct,
                deaths: player.deaths,
                damage: player.damage,
              };

            mappedMatch.players.push(mappedPlayer);
          }

          result.push(mappedMatch);
        }
      }
    }

    return result;
  },

  mapTournamentModel: (data: any): TournamentModel => {
    const tournamentModel = data as TournamentModel;

    if (!tournamentModel) {
      return tournamentModel;
    }

    /** Define tournament mode */
    if (tournamentModel.teamSize) {
      switch (tournamentModel.teamSize) {
        case 1:
          tournamentModel.mode = "Solo";
          break;
        case 2:
          tournamentModel.mode = "Duos";
          break;
        case 3:
          tournamentModel.mode = "Trios";
          break;
        case 4:
          tournamentModel.mode = "Quads";
          break;
        default:
          tournamentModel.mode = "Unknown";
          break;
      }
    }

    /** Define tournament slug */
    if (tournamentModel.title) {
      let str = tournamentModel.title || "";
      str = toSlug(str);
      tournamentModel.slug = str + "-" + tournamentModel.id;
    }

    /** Define tournament imageUrl */
    if (!tournamentModel.imageUrl) {
      tournamentModel.imageUrl = [APP_URL || "", codDefaultBanner.src.replace(/^\/+/, "")].join("/");
    }

    /** Define featured tournament featuredImgUrl */
    if (!tournamentModel.featuredImgUrl) {
      tournamentModel.featuredImgUrl = "";
    }

    /** Define tournament url */
    tournamentModel.url = [APP_URL || "", "tournaments", tournamentModel.slug].join("/");

    /* Define tournament duration */
    tournamentModel.duration = moment
      .duration(tournamentModel.endDate - tournamentModel.startDate)
      .locale("en")
      .humanize(false);

    /** GAMEF-518 Tournament Prizes */
    if (tournamentModel?.prizeDetails?.prizes?.length) {
      /** GAMEF-647 Prize pool calculation */
      const divisionCount = 4, // Recruit, Operator, etc..
        cashPrizes = tournamentModel.prizeDetails.prizes.filter((prize) => prize.prizeType === "CASH"),
        totalCashPrize = cashPrizes.reduce((prev, curr) => prev + curr.amount, 0),
        creditPrizes = tournamentModel.prizeDetails.prizes.filter((prize) => prize.prizeType === "CREDIT"),
        totalCreditPrize = creditPrizes.reduce((prev, curr) => prev + curr.amount, 0);

      /** Override the old prizePool from the accumulated cash prizes */
      if (totalCashPrize) {
        // Cash prize * division count
        const prizeFinal = totalCashPrize * divisionCount;
        tournamentModel.prizePool = prizeFinal;
        tournamentModel.prizePoolDisplay = formatCurrency(prizeFinal, tournamentModel.currency);
      } else if (totalCreditPrize) {
        // Credit prize * division count
        const prizeFinal = totalCreditPrize * divisionCount;
        tournamentModel.prizePool = prizeFinal;
        tournamentModel.prizePoolDisplay = [formatCurrency(prizeFinal, tournamentModel.currency), "GF Credit"].join(" ");
      } else {
        // Just a fallback, this shouldn't happen.
        tournamentModel.prizePool = 0;
        tournamentModel.prizePoolDisplay = "$0";
      }
    }

    /**
     * External CMS content ID
     * If tournament is NOT completed or cancelled - default is 'default_open', otherwise 'default_01';
     */
    const defaultContentId = !~[TournamentState.COMPLETED, TournamentState.CANCELLED].indexOf(tournamentModel.status) ? "default_open" : "default_01";
    let contentId: string = tournamentModel.contentId || defaultContentId;

    if (contentId) {
      const partMatches = contentId.match(/^(default_.*)_.*$/i);
      if (partMatches && partMatches.length && partMatches[1]) {
        contentId = partMatches[1];
      }
    }

    tournamentModel.contentId = contentId;

    /** Creates schema */
    tournamentModel.schema = {
      "@context": "https://schema.org",
      "@type": "Event",
      name: tournamentModel.title,
      description: tournamentModel.description,
      startDate: moment.utc(tournamentModel.startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      endDate: moment.utc(tournamentModel.endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      image: tournamentModel.imageUrl,
      eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
      eventStatus: "https://schema.org/EventScheduled",
      location: {
        "@type": "VirtualLocation",
        url: tournamentModel.url,
      },
      offers: {
        "@type": "Offer",
        price: tournamentModel.registrationFee,
        priceCurrency: tournamentModel.currency,
        url: tournamentModel.url,
        availability: Date.now() > tournamentModel.endDate ? "https://schema.org/Discontinued" : "https://schema.org/InStock",
        validFrom: moment.utc(tournamentModel.startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
        validThrough: moment.utc(tournamentModel.endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      },
      organizer: {
        "@type": "Organization",
        name: "GameFace",
        url: APP_URL,
      },
      performer: {
        "@type": "Organization",
        name: "GameFace",
        url: APP_URL,
      },
    };

    return tournamentModel;
  },

  /**
   * Parses IRosterMember raw response to a valid model. If the raw response or its members are falsy values, it will be provided as defaults.
   * @param payload - Raw response of IRosterMember in 'any' type
   * @return IRosterMember
   * @description "payload.avatar_url" in the response is expected to have formed a valid Discord URL. Do not confuse this with UserHttp.mapDiscordUser.
   */
  mapRosterMember: (payload: any): IRosterMember => {
    return {
      rosterId: payload?.roster_id || 0,
      userId: payload?.user_id || "",
      platform: payload?.platform?.toLowerCase() || "psn",
      status: payload?.status?.toLowerCase() || "unregistered",
      username: payload?.username || "",
      avatar: payload?.avatar_url || discord_user_avatar_svg.src,
      gamesPlayed: payload?.games_played || 0,
      kdRatio: payload?.kd_ratio || 0,
    };
  },

  /**
   * Parses IRoster raw response to a valid model. If the raw response or its members are falsy values, it will be provided as defaults.
   * @param payload - Raw response of IRoster in 'any' type
   * @return IRoster
   */
  mapRoster: (payload: { tournament_id: number; roster_id: number; roster_name: string; roster_captain: any; roster_members: any[]; status: string; tier: number; skill_level: number }): IRoster => {
    return {
      tournamentId: payload?.tournament_id || 0,
      rosterId: payload?.roster_id || 0,
      rosterName: payload?.roster_name || "",
      rosterCaptain: TournamentMappers.mapRosterMember(payload?.roster_captain),
      rosterMembers: payload?.roster_members?.length ? payload.roster_members.map((member: any) => TournamentMappers.mapRosterMember(member)) : [],
      status: (payload?.status || "").toLowerCase() as IRosterStatus,
      tier: payload?.tier || 0,
      skillLevel: payload?.skill_level || 0,
    };
  },
};

export default TournamentMappers;
