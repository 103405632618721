import { useCallback, useEffect } from "react";
import { useAppDispatch } from "@app/state/store";
import { UserActions } from "@ducks/user";
import { withUserInfo, IWithUserInfo } from "./hocs/withUserInfo";
import { HttpError } from "@app/http/base";
import { useRouter } from "next/router";

type IAuthWorker = IWithUserInfo;

function AuthWorker(props: IAuthWorker) {
  const { cognitoUser, userInfo, userInfoFetching } = props;
  const dispatch = useAppDispatch();
  const router = useRouter();
  const doSignIn = useCallback(() => {
    if (cognitoUser && !userInfo && !userInfoFetching) {
      dispatch(UserActions.login({ cognitoUser }))
        .then(({ type, payload }) => {
          if (type === UserActions.login.rejected.toString()) {
            throw payload;
          }
          // All good, login is successful;
        })
        .catch((err: HttpError) => {
          if (err.error === "USER_MISSING_ATTRIBUTES") {
            const errUser = (err?.entities || [])[0];

            if (errUser && errUser.username) {
              router.push("/auth/complete-registration?u=" + encodeURIComponent(errUser.username));
            } else {
              router.push("/auth/complete-registration");
            }
          }
        });
    }
  }, [cognitoUser, userInfo, userInfoFetching]);

  useEffect(() => {
    if (cognitoUser) {
      const timer = window.setTimeout(doSignIn, 1e3);

      return () => {
        if (timer) {
          window.clearTimeout(timer);
        }
      };
    }
  }, [cognitoUser]);

  return null;
}

export default withUserInfo(AuthWorker);
