import { UserInfo } from "@models/UserModel";
import { CognitoUser } from "@aws-amplify/auth";

const UserMappers = {
  mapUserInfo: (payload: any, cognitoUser?: CognitoUser): UserInfo => {
    const userInfo = {
      id: payload?.gameface_user_id || 0,
      email: payload?.user_email || "",
      username: payload?.username || "",
      discriminator: payload?.discriminator || "",
      phoneNumber: payload?.phone_number || "",
      dateOfBirth: payload?.date_of_birth || "",
      avatar: payload?.picture_url || "",
      platforms: payload.platforms || [],

      // Extra third-party property;
      cognitoUser: null,

      // Backward compat properties
      name: [payload?.username || "", payload?.discriminator || ""].filter(Boolean).join("#"),
    } as UserInfo;

    if (cognitoUser) {
      userInfo.cognitoUser = cognitoUser;
    }

    return userInfo;
  },
};

export default UserMappers;
