import React, { useEffect } from "react";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import Head from "next/head";
import * as Sentry from "@sentry/nextjs";
import { IS_PROD, META_DESCRIPTION, META_TITLE, META_TWITTER, META_URL, SENTRY_DSN } from "@app/utilities/constants";

/**
 * Configure moment
 */
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

/**
 * Configure Sentry
 */
Sentry.init({
  dsn: SENTRY_DSN,
  environment: IS_PROD ? "production" : "develop",
  beforeBreadcrumb: (breadcrumb) => {
    if (breadcrumb?.category === "fetch") {
      const url = breadcrumb?.data?.url || "";
      if (~url.indexOf("_next/static/css")) {
        return null;
      } else if (~url.indexOf("stats.g.doubleclick.net")) {
        return null;
      }
    }

    return breadcrumb;
  },
});

const Layout: React.FC = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const isErrorPage = router.pathname.indexOf("/4") === 0 || router.pathname.indexOf("/5") === 0;

      if (isErrorPage) {
        return;
      }
      /**
       * Cloudfront Cache Fix
       * When cloudfront cache doesn't pass query parameters to the client,
       * Run this for the first-time only when document is loaded, to sync the queries.
       */
      const url = router.asPath,
        urlQs = url.slice(url.indexOf("?")),
        clientQuery = qs.parse(urlQs, { ignoreQueryPrefix: true }),
        routerQuery = Object.assign({}, router.query);

      // Remove Dynamic URLS path from routerQuery.
      const re = /\[[^\]]+\]/g,
        matches = (router?.pathname.match(re) || [])
          .map((match) => {
            return match?.slice(1, match.length - 1);
          })
          .filter(Boolean);

      matches.forEach((dynamicPath) => {
        if (Object.prototype.hasOwnProperty.call(routerQuery, dynamicPath)) {
          delete routerQuery[dynamicPath];
        }
      });

      const needsUpdate = _.size(routerQuery) !== _.size(clientQuery);

      if (needsUpdate) {
        router.replace(router.asPath, router.asPath, { shallow: true });
      }
    }
  }, []);

  return (
    <main className="main-container font-play position-relative" data-testid="main-container">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta key="title" name="title" content={META_TITLE} />
        <meta key="description" name="description" content={META_DESCRIPTION} />

        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:title" property="og:title" content={META_TITLE} />
        <meta key="og:description" property="og:description" content={META_DESCRIPTION} />
        <meta key="og:url" property="og:url" content={META_URL} />
        <meta key="og:image" property="og:image" content={META_URL + "/icons/icon-512x512.png"} />

        <meta key="twitter:site" name="twitter:card" content="summary" />
        <meta key="twitter:site" name="twitter:site" content={META_TWITTER} />
        <meta key="twitter:url" name="twitter:url" content={META_URL} />
        <meta key="twitter:title" name="twitter:title" content={META_TITLE} />
        <meta key="twitter:description" name="twitter:description" content={META_DESCRIPTION} />
        <meta key="twitter:image" name="twitter:image" content={META_URL + "/icons/icon-512x512.png"} />
        <meta key="twitter:creator" name="twitter:creator" content={META_TWITTER} />

        <meta key="item-prop-title" itemProp="title" content={META_TITLE} />
        <meta key="item-prop-description" itemProp="description" content={META_DESCRIPTION} />
        <meta name="robots" content="index,follow" />
      </Head>
      {children}
    </main>
  );
};

export default Layout;
