import { AxiosResponse } from "axios";
import { apiHttp, HttpError } from "./base";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { GET, POST, PUT } from "@app/utilities";
import { IPostLoginAttributeOverrides, ISetUserProfile, PlatformAbbrMap, PlatformType, PostLoginResultType, UserInfo } from "@models/UserModel";
import UserMappers from "./mappers/UserMappers";

const userHttp = {
  /**
   * @todo Remove this
   * @deprecated Ever since we migrate to Cognito, this is not used anymore
   */
  me: (): Promise<UserInfo> => {
    return apiHttp(GET, "/auth/me", null)
      .then((response: AxiosResponse) => {
        if (response.status === 200 && response.data) {
          return UserMappers.mapUserInfo(response.data);
        }

        throw new HttpError("auth-me-failed", "User is not verified", [], 400);
      })
      .catch((err: HttpError) => {
        throw err;
      });
  },
  postLogin: async (cognitoUser: CognitoUser, attributeOverrides?: IPostLoginAttributeOverrides): Promise<UserInfo> => {
    const userSession = cognitoUser?.getSignInUserSession(),
      id_token = userSession.getIdToken().getJwtToken(),
      params = {
        id_token,
      };

    if (attributeOverrides) {
      Object.assign(params, {
        attribute_overrides: attributeOverrides,
      });
    }

    return apiHttp(POST, "/auth/post-login", params, {
      noAuthHeader: true,
    }).then((response: AxiosResponse) => {
      const { result_type, user } = response?.data || {},
        resultType = result_type as PostLoginResultType,
        bffUserInfo = UserMappers.mapUserInfo(user || {}, cognitoUser);

      if (user) {
        return bffUserInfo;
      }

      throw new HttpError(resultType || "post-login-failed", "Failed to fetch user information", [], 400);
    });
  },
  setProfileInfo: async (userProfile?: ISetUserProfile): Promise<UserInfo> => {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

    if (!cognitoUser) {
      return null;
    } else {
      return apiHttp(PUT, "/profile/setProfileInfo", userProfile).then((response: AxiosResponse) => {
        const bffUserInfo = UserMappers.mapUserInfo(response?.data || {}, cognitoUser);
        return bffUserInfo;
      });
    }
  },

  logout: (): Promise<Boolean> => {
    return apiHttp(PUT, "/auth/logout").then(() => {
      return true;
    });
  },

  setCodProfile: (platform_long: string, platform_id: string): Promise<any> => {
    const platform = PlatformAbbrMap[platform_long as PlatformType];

    return apiHttp(PUT, "/profile/setCodProfile", {
      platform,
      platform_id,
    }).then((response: AxiosResponse) => {
      const { data } = response;

      if (data) {
        return {
          platform,
          platform_id,
        };
      }

      return {
        platform: null,
        platform_id: null,
      };
    });
  },
};

export default userHttp;
